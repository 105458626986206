<template>
  <div class="component-reports-long-term-performance-budget-table-result">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" xl="5" class="mb-2">
                  <h2 v-if="isIncomeExpenseChart(showChart)">{{ $t(translationPath + 'title_result', {start_year: yearStart, end_year: yearEnd }) }}</h2>
                  <h2 v-if="isAssetLiabilityChart(showChart)">{{ $t(translationPath + 'title_balance', {start_year: yearStart, end_year: yearEnd }) }}</h2>
                  <h2 v-if="isCashflowChart(showChart)">{{ $t(translationPath + 'title_cashflow', {start_year: yearStart, end_year: yearEnd }) }}</h2>
                </b-col>
                <b-col cols="12" xl="7" class="text-right">

                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( 'reports.budget.lifeplan.charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('cashflow-per-year-chart')" v-bind:class="{ active: showChart === 'cashflow-per-year-chart' }">{{$t( 'reports.budget.lifeplan.charts.cashflow-per-year-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('cashflow-stepped-chart')" v-bind:class="{ active: showChart === 'cashflow-stepped-chart' }">{{$t( 'reports.budget.lifeplan.charts.cashflow-stepped-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('cashflow-acc-chart')" v-bind:class="{ active: showChart === 'cashflow-acc-chart' }">{{$t( 'reports.budget.lifeplan.charts.cashflow-acc-chart')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('balance-per-year')" v-bind:class="{ active: showChart === 'balance-per-year' }">{{$t( 'reports.budget.lifeplan.charts.balance-per-year')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('assets-vs-liabilities-grouped-chart')" v-bind:class="{ active: showChart === 'assets-vs-liabilities-grouped-chart' }">{{$t( 'reports.budget.lifeplan.charts.assets-vs-liabilities-grouped-chart')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('income-vs-expenses-stepped-chart')" v-bind:class="{ active: showChart === 'income-vs-expenses-stepped-chart' }">{{$t( 'reports.budget.lifeplan.charts.income-vs-expenses-stepped-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('income-vs-expenses-grouped-chart')" v-bind:class="{ active: showChart === 'income-vs-expenses-grouped-chart' }">{{$t('reports.budget.lifeplan.charts.income-vs-expenses-grouped-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('result-per-year')" v-bind:class="{ active: showChart === 'result-per-year' }">{{$t( 'reports.budget.lifeplan.charts.result-per-year')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('result-per-year-acc-chart')" v-bind:class="{ active: (showChart === 'result-per-year-acc-chart') }">{{$t('reports.budget.lifeplan.charts.result-per-year-acc-chart')}}</b-dropdown-item>
                  </b-dropdown>
                  <column-toggler v-if="false" :columns="options" @toggle="onColumnToggle" />
                  <toggle-budgets-dropdown
                    v-if="budgets.length > 1"
                    ref="BudgetDropdown"
                    :budgets="budgets"
                    :initial-budget-id="budgetId"
                    @change="onBudgetChange"
                  />
                </b-col>
              </b-row>
            </template>

            <div v-if="isIncomeExpenseChart(showChart)" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_result', {start_year: yearStart, end_year: yearEnd })"></div>
            <div v-if="isAssetLiabilityChart(showChart)" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_balance', {start_year: yearStart, end_year: yearEnd })"></div>
            <div v-if="isCashflowChart(showChart)" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_cashflow', {start_year: yearStart, end_year: yearEnd })"></div>

            <b-table
              class="spirecta-simple-table long-term-performance-budget-table mb-0"
              show-empty
              hover
              responsive
              striped
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="tableRowSetClass"
            >
              <template v-slot:table-busy><loader /></template>

              <template v-slot:cell(label)="row">
                <template v-if="row.item.row_type === 'total'">{{ row.value }}</template>
                <template v-else-if="row.item.row_type === 'subtotal'">{{ row.value }}</template>
                <template v-else-if="row.item.row_type === 'account-group'">
                  <strong>{{ row.value }}</strong> <span class="small">(x {{apiData.meta.multiplier}}{{currentCOA.currency}})</span></template>
                <template v-else>
                  <template v-if="row.item.account_type">
                    <b-link href="#" @click="(event) => onTitleClick(event, row.item)">{{ row.value }}</b-link>
                  </template>
                  <template v-else>{{ row.value }}</template>
                </template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in isLoaded ? this.apiData.meta.periods : []">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else-if="row.item.row_type === 'total'">
                  <span :class="row.unformatted >= 0 ? 'text-green' : 'text-red'" v-bind:key="sPeriod">{{row.value}}</span>
                </template>
                <template v-else>{{row.value}}</template>
              </template>

            </b-table>

            <div class="text-regular text-small" v-html="$t(translationPath+'start_explanation')"></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'
import ColumnToggler from '@/components/common/ColumnToggler'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'

export default {
  name: 'LifeplanBudgetReportTableResult',
  mixins: [LifeplanBudgetReportMixin],
  components: { Loader, ColumnToggler, ToggleBudgetsDropdown },
  props: {
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    simulationType: { type: String, default: 'performance' },
    selectedChart: { type: String, default: 'income-vs-expenses-stepped-chart' },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    apiData: {
      type: Object,
      default: function () {
        return {
          periods: []
        }
      }
    },
    budgets: {
      type: Array,
      default: function () {
        return []
      }
    },
    budgetId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.lifeplan.table.summary.',
      tableData: [],
      showChart: 'income-vs-expenses-stepped-chart',
      yearStart: 0,
      yearEnd: 0,
      ieCharts: [
        'result-per-year',
        'result-per-year-acc-chart',
        'income-vs-expenses-grouped-chart',
        'income-vs-expenses-stepped-chart'
      ],
      alCharts: [
        'balance-per-year',
        'balance-result-area-chart-acc',
        'assets-vs-liabilities-grouped-chart'
      ],
      cashflowCharts: [
        'cashflow-per-year-chart',
        'cashflow-stepped-chart',
        'cashflow-acc-chart'
      ]
    }
  },
  computed: {
    tableColumns () {
      return this.buildTableColumns('accounts', this.isLoaded, this.apiData)
    }
  },
  mounted () {
    this.showChart = this.selectedChart
  },
  methods: {
    toggleChart (showChart) {
      const prev = this.isIncomeExpenseChart(this.showChart).toString() + this.isAssetLiabilityChart(this.showChart).toString() + this.isCashflowChart(this.showChart).toString()
      const next = this.isIncomeExpenseChart(showChart).toString() + this.isAssetLiabilityChart(showChart).toString() + this.isCashflowChart(showChart).toString()

      this.showChart = showChart

      if (prev !== next) {
        this.makeRows()
      }

      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    onColumnToggle (data) {
      this.$emit('column-toggle', data)
    },
    onTitleClick (event, item) {
      event.preventDefault()
      const data = {
        tab: item.account_type,
        chart: item.chart ? item.chart : null,
        group_id: item.group_id ? item.group_id : null
      }
      this.$emit('click-title', data)
    },
    isIncomeExpenseChart (chartId) {
      return this.ieCharts.indexOf(chartId) !== -1
    },
    isAssetLiabilityChart (chartId) {
      return this.alCharts.indexOf(chartId) !== -1
    },
    isCashflowChart (chartId) {
      return this.cashflowCharts.indexOf(chartId) !== -1
    },
    makeRows () {
      if (!this.isLoaded) { return [] }
      this.yearStart = this.apiData.meta.start_year
      this.yearEnd = this.apiData.meta.end_year

      this.tableData = []
      const oTotalRow = Object.assign({ label: '', row_type: 'total' })
      const oEmptyRow = { row_type: 'empty-row', label: '', average: 0, total: 0 }

      // Income / Expense table for account overview
      if (this.isIncomeExpenseChart(this.showChart)) {
        /**
         * Income / Expense-table
         */
        oTotalRow.label = this.$t('common.result')
        this.tableData.push({ row_type: 'account-group', label: this.$t('common.incomes'), account_type: 'income' })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('income')]
        this.tableData.push(oEmptyRow)
        this.tableData.push({ row_type: 'account-group', label: this.$t('common.expenses'), account_type: 'expense' })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('expense')]
        this.tableData.push(oEmptyRow)

        // Total row
        for (const index in this.apiData.meta.periods) {
          const sPeriod = this.apiData.meta.periods[index]
          oTotalRow[sPeriod] = this.apiData.totals.result.period_totals[sPeriod]
        }
        this.tableData.push({ ...oTotalRow })
      } else if (this.isAssetLiabilityChart(this.showChart)) {
        /**
         * Asset / Liability-table
         */
        oTotalRow.label = this.$t('common.networth')

        this.tableData.push({ row_type: 'account-group', label: this.$t('common.assets') })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('asset')]
        this.tableData.push(oEmptyRow)
        this.tableData.push({ row_type: 'account-group', label: this.$t('common.liabilities') })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('liability')]
        this.tableData.push(oEmptyRow)

        // Total row
        for (const index in this.apiData.meta.periods) {
          const sPeriod = this.apiData.meta.periods[index]
          oTotalRow[sPeriod] = this.apiData.totals.networth.period_totals[sPeriod]
        }
        this.tableData.push(oTotalRow)
      } else if (this.isCashflowChart(this.showChart)) {
        /**
         * CASHFLOW TABLE DATA
         */
        const oIncomeRow = Object.assign({ label: this.$t('common.incomes'), row_type: 'account', account_type: 'income' })
        const oCfIncomingRow = Object.assign({ label: this.$t('common.subtotal'), row_type: 'subtotal' })
        const oCfOutgoingRow = Object.assign({ label: this.$t('common.subtotal'), row_type: 'subtotal' })
        const oExpenseRow = Object.assign({ label: this.$t('common.expenses'), row_type: 'account', account_type: 'expense' })
        const oAmortizationRow = Object.assign({ label: this.$t(this.translationPath + 'amortization'), row_type: 'account', account_type: 'liability', chart: 'liability-amortization' })
        const oDepositRow = Object.assign({ label: this.$t(this.translationPath + 'deposits'), row_type: 'account', account_type: 'asset', chart: 'asset-deposit' })
        const oWithdrawalRow = Object.assign({ label: this.$t(this.translationPath + 'withdrawals'), row_type: 'account', account_type: 'asset', chart: 'asset-withdrawal' })
        const oCashflowTotalRow = Object.assign({ label: this.$t(this.translationPath + 'cashflow_total'), row_type: 'total' })
        const oCashflowAccRow = Object.assign({ label: this.$t(this.translationPath + 'cashflow_acc'), row_type: 'account' })

        // Total row
        for (const index in this.apiData.meta.periods) {
          const sPeriod = this.apiData.meta.periods[index]
          oIncomeRow[sPeriod] = this.apiData.totals.income.period_totals[sPeriod]
          oCfIncomingRow[sPeriod] = this.apiData.totals.cashflow.incoming[sPeriod]
          oCfOutgoingRow[sPeriod] = this.apiData.totals.cashflow.outgoing[sPeriod]
          oExpenseRow[sPeriod] = this.apiData.totals.expense.period_totals[sPeriod]
          oWithdrawalRow[sPeriod] = this.apiData.totals.asset.withdrawals[sPeriod]
          oAmortizationRow[sPeriod] = this.apiData.totals.liability.amortization[sPeriod]
          oDepositRow[sPeriod] = this.apiData.totals.asset.deposits[sPeriod]
          oCashflowTotalRow[sPeriod] = this.apiData.totals.cashflow.period_totals[sPeriod]
          oCashflowAccRow[sPeriod] = this.apiData.totals.cashflow.period_totals_acc[sPeriod]
        }
        this.tableData.push({ row_type: 'account-group', label: this.$t(this.translationPath + 'incoming') })
        this.tableData.push(oIncomeRow)
        this.tableData.push(oWithdrawalRow)
        this.tableData.push(oCfIncomingRow)
        this.tableData.push(oEmptyRow)

        this.tableData.push({ row_type: 'account-group', label: this.$t(this.translationPath + 'outgoing') })
        this.tableData.push(oExpenseRow)
        this.tableData.push(oAmortizationRow)
        this.tableData.push(oDepositRow)
        this.tableData.push(oCfOutgoingRow)
        this.tableData.push(oEmptyRow)
        this.tableData.push(oCashflowTotalRow)
        this.tableData.push(oCashflowAccRow)
        // this.tableData.push(oNetworthRow)
      } else {
        return []
      }
      this.tableData.push(oEmptyRow)

      /**
       * Some minor fixes
       *  - change meta for summary table where we use account-groups as accounts for css to look nice
       *  - fix our fake group labels
       */
      for (const idx in this.tableData) {
        if (this.tableData[idx].row_type === 'account_group') {
          this.tableData[idx].row_type = 'account'
        }
        switch (this.tableData[idx].label) {
          case 'spirecta.withdrawals_group' :
            this.tableData[idx].label = this.$t('reports.budget.lifeplan.table.summary.withdrawal_from_assets')
            break
          case 'spirecta.interest_group' :
            this.tableData[idx].label = this.$t('reports.budget.lifeplan.table.summary.interest_from_liabilities')
            break
        }
      }
    },
    buildTableDataRows (accountType) {
      const tableData = []
      const oColumnLabels = {}

      let oAccountGroupRow = {}
      const oTotalRow = Object.assign({ label: this.$t('reports.budget.lifeplan.table.summary.total_' + accountType), row_type: 'subtotal' })

      // Create column label object to use for account group headers
      for (const index in this.apiData.meta.periods) {
        const sPeriod = this.apiData.meta.periods[index]
        oColumnLabels[sPeriod] = this.translatePeriodColumnTitleIntoNiceName(this.apiData.meta.periods[index], this.apiData.meta.resolution)
        oTotalRow[sPeriod] = this.apiData.totals[accountType].period_totals[sPeriod]
      }

      // Loop all groups
      for (const iGroupId in this.apiData.groups[accountType]) {
        // Create from API data
        oAccountGroupRow = Object.assign(
          { row_type: 'account_group', account_type: accountType, group_id: this.apiData.groups[accountType][iGroupId].id },
          this.apiData.groups[accountType][iGroupId]
        )

        for (const index in oAccountGroupRow.period_totals) {
          oAccountGroupRow[index] = oAccountGroupRow.period_totals[index]
        }

        tableData.push({ ...oAccountGroupRow })
      }// end loop all groups

      tableData.push({ ...oTotalRow })

      return tableData
    }
  },
  watch: {
    apiData: {
      handler: 'makeRows',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'makeRows',
      deep: true,
      immediate: true
    },
    activeTab: {
      handler: 'makeRows',
      deep: true,
      immediate: true
    },
    budgetId () {
      process.nextTick(() => {
        if (this.$refs.BudgetDropdown) {
          this.$refs.BudgetDropdown.$selectItem(this.budgetId)
        }
      })
    }
  }
}
</script>
