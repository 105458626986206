import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    buildTableColumns (sTableTypeToBuild, isLoaded, apiData) {
      if (!this.isLoaded) { return [] }

      const aColumns = [{ key: 'label', label: '', sortable: false, class: 'text-left td-title', stickyColumn: true }]

      for (const sPeriod in this.apiData.meta.periods) {
        aColumns.push({ key: '' + this.apiData.meta.periods[sPeriod], label: this.translatePeriodColumnTitleIntoNiceName(this.apiData.meta.periods[sPeriod], this.apiData.meta.resolution).toString(), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormatter })
      }

      return aColumns
    },
    gomycell (key) {
      return `cell(${key})`
    },
    translatePeriodColumnTitleIntoNiceName (sValue, sResolution) {
      const iYear = sValue.toString().substring(0, 4)
      let sReturnLabel = null

      if (sResolution === 'month') {
        const iMonth = parseInt(sValue.substring(5))
        sReturnLabel = this.$t('common.months.month' + iMonth + '_short')
        if (iMonth === 1) {
          sReturnLabel = sReturnLabel + ' ' + iYear
        }
      } else {
        sReturnLabel = iYear.toString()
      }

      return sReturnLabel
    },
    amountFormatter (value) {
      return value ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0) : 0
    },
    percentageFormatter (value) {
      if (typeof value === 'number') {
        return parseFloat(value).toFixed(1).toString() + '%'
      }
      return value
    },
    tableRowSetClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    },
    onBudgetChange (budgetId) {
      this.$emit('budget-change', budgetId)
    }
  }
}
