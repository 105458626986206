<template>
  <b-modal
    centered
    ref="Modal"
    modal-class="spirecta-modal lifeplan-budget-report-create-values-modal"
    size="xl"
    @show="onShow"
    @hide="onHide"
    @hidden="onHidden"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title', { name: accountTitle }) }}
    </template>

    <b-tabs card class="white-tabs" v-model="tabIndex">
      <!-- /** PER PERIOD TAB :: START **/ -->
      <b-tab :title="$t(translationPath + 'tabs.period')">
        <b-row no-gutters>
          <b-col cols="12">
            <div class="text-regular" v-html="$t(translationPath + 'period.description_'+accountType, {account: accountTitle, account_id: accountId})"></div>
          </b-col>
        </b-row>

        <b-form-group
          label-class="pr-4 text-black"
          label-for="period-start"
          label-cols="5"
          required
          :label="$t(translationPath + 'period.start')"
          :invalid-feedback="periodStartInvalidFeedback"
        >
          <b-form-input
            id="period-start"
            v-model="formData.period.start"
            placeholder="YYYY-MM"
            :state="$v.formData.period.start.$dirty ? !$v.formData.period.start.$error : null"
          />
        </b-form-group>
        <b-form-group
          label-class="pr-4 text-black"
          label-for="period-end"
          label-cols="5"
          required
          :label="$t(translationPath + 'period.end')"
          :invalid-feedback="periodEndInvalidFeedback"
        >
          <b-form-input
            id="period-end"
            v-model="formData.period.end"
            placeholder="YYYY-MM"
            :state="$v.formData.period.end.$dirty ? !$v.formData.period.end.$error : null"
          />
        </b-form-group>
        <!-- INCOME / EXPENSE -->
        <b-form-group v-if="accountType === 'income' || accountType === 'expense'"
          label-class="pr-4 text-black"
          label-for="period-amount"
          label-cols="5"
          required
          :label="$t(translationPath + 'period.amount_per_month')"
          :description="$t(translationPath + 'period.amount_per_month_description',{amount: formData.period.amount * 12 })"
        >
          <currency-input
            ref="CurrencyInputPeriodAmount"
            id="period-amount"
            v-model="formData.period.amount"
            :precision="0"
            :min="0"
          />
        </b-form-group>

        <template v-if="accountType === 'asset' || accountType === 'liability'">
          <b-form-group
            label-class="pr-4 text-black"
            label-for="period-starting_value"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.starting_value', {start_date: formData.period.start})"
          >
            <currency-input
              ref="CurrencyInputPeriodDeposit"
              id="period-starting_value"
              v-model="formData.period.starting_value"
              :precision="0"
              :min="0"
            />
          </b-form-group>
        </template>

        <!-- PER PERIOD: ASSET START -->
        <template v-if="accountType === 'asset'">
          <b-form-group
            label-class="pr-4 text-black"
            label-for="period-roi"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.roi')"
          >
            <percentage-input
              id="period-roi"
              v-model="formData.period.roi"
              :precision="1"
              :step="0.5"
              slider
              sliderPosition="left"
              :sliderXl="9"
              :sliderMd="8"
              :max="25"
            ></percentage-input>
          </b-form-group>

          <b-form-group
            label-class="pr-4 text-black"
            label-for="period-deposit"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.deposit')"
            :description="$t(translationPath + 'period.amount_per_month_description',{amount: formData.period.deposit * 12 })"
          >
            <currency-input
              ref="CurrencyInputPeriodDeposit"
              id="period-deposit"
              v-model="formData.period.deposit"
              :precision="0"
              :min="0"
            />
          </b-form-group>
          <b-form-group
            label-class="pr-4 text-black"
            label-for="period-withdrawal"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.withdrawal')"
            :description="$t(translationPath + 'period.amount_per_month_description',{amount: formData.period.withdrawal * 12 })"
          >
            <currency-input
              ref="CurrencyInputPeriodWithdrawal"
              id="period-withdrawal"
              v-model="formData.period.withdrawal"
              :precision="0"
              :min="0"
            />
          </b-form-group>
        </template>
        <!-- PER PERIOD: ASSET END-->

        <!-- PER PERIOD: LIABILITY START -->
        <template v-if="accountType === 'liability'">
          <b-form-group
            label-class="pr-4 text-black"
            label-for="period-interest"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.interest')"
          >
            <percentage-input
              id="period-interest"
              v-model="formData.period.interest"
              :precision="2"
              :step="0.25"
              slider
              sliderPosition="left"
              :sliderXl="9"
              :sliderMd="8"
              :max="25"
            ></percentage-input>
          </b-form-group>

          <b-form-group
            label-class="pr-4 text-black"
            label-for="period-amortization_type"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.amortization_type')"
          >
            <b-form-select
              id="period-amortization_type"
              v-model="formData.period.amortization_type"
              :options="[
                {value: 'percentage', text: $t(translationPath + 'period.amortization_percentage')},
                {value: 'amount', text: $t(translationPath + 'period.amortization_amount')}
              ]"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            v-if="formData.period.amortization_type === 'percentage'"
            label-class="pr-4 text-black"
            label-for="period-amortization-percentage"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.amortization_percentage')"
          >
            <percentage-input
              id="period-amortization-percentage"
              v-model="formData.period.amortization_percentage"
              :precision="2"
              :step="1"
              slider
              sliderPosition="left"
              :sliderXl="9"
              :sliderMd="8"
              :max="100"
            ></percentage-input>
          </b-form-group>

          <b-form-group
            v-if="formData.period.amortization_type === 'amount'"
            label-class="pr-4 text-black"
            label-for="period-amortization_amount"
            label-cols="5"
            required
            :label="$t(translationPath + 'period.amortization_amount')"
          >
            <currency-input
              ref="CurrencyInputPeriodDeposit"
              id="period-amortization_amount"
              v-model="formData.period.amortization_amount"
              :precision="0"
              :min="0"
            />
          </b-form-group>
        </template>
        <!-- PER PERIOD: LIABILITY END -->
      </b-tab>

      <!-- /** PER YEAR TAB :: START **/ -->
      <b-tab :title="$t(translationPath + 'tabs.per_year')" v-if="accountType === 'income' || accountType === 'expense'">
        <b-row no-gutters>
          <b-col cols="12"><p>{{ $t(translationPath + 'per_year.description') }}</p></b-col>
        </b-row>
        <b-row v-if="isValuesLoaded" no-gutters>
          <b-col cols="3"><p class="ml-1">{{ $t(translationPath + 'per_year.year') }}</p></b-col>
          <b-col :cols="['income', 'expense'].indexOf(accountType) !== -1 ? 9 : 3"><p class="ml-1">{{ $t(translationPath + 'per_year.amount_per_year') }}</p></b-col>

          <template v-if="accountType === 'asset'">
            <b-col cols="3"><p>{{ $t(translationPath + 'per_year.deposit_per_year') }}</p></b-col>
            <b-col cols="3"><p>{{ $t(translationPath + 'per_year.withdrawal_per_year') }}</p></b-col>
          </template>

          <template v-if="accountType === 'liability'">
            <b-col cols="3"><p>{{ $t(translationPath + 'per_year.interest_per_year') }}</p></b-col>
            <b-col cols="3"><p>{{ $t(translationPath + 'per_year.amortization_per_year') }}</p></b-col>
          </template>
        </b-row>

        <template v-if="!isValuesLoaded"><loader /></template>
        <template v-else>
          <b-form-group
            v-for="year in yearsRange"
            :key="year"
            label-class="pr-4 text-black"
            :label-for="'per-year-amount-' + year"
            label-cols="3"
            required
            :label="year.toString()"
          >
            <b-row no-gutters>
              <b-col
                :cols="['income', 'expense'].indexOf(accountType) !== -1 ? 12 : 4"
                :class="{ 'pr-2': ['income', 'expense'].indexOf(accountType) === -1 }"
              >
                <currency-input
                  :ref="'CurrencyInputPerYearAmount' + year"
                  :id="'per-year-amount-' + year"
                  v-model="formData.perYear.amount[year]"
                  :precision="0"
                  :min="0"
                />
              </b-col>
              <!-- Per year ASSET -->
              <template v-if="accountType === 'asset'">
                <b-col cols="4" class="pr-2">
                  <currency-input
                    :ref="'CurrencyInputPerYearDeposit' + year"
                    :id="'per-year-deposit-' + year"
                    v-model="formData.perYear.deposit[year]"
                    :precision="0"
                    :min="0"
                  />
                </b-col>
                <b-col cols="4">
                  <currency-input
                    :ref="'CurrencyInputPerYearWithdrawal' + year"
                    :id="'per-year-withdrawal-' + year"
                    v-model="formData.perYear.withdrawal[year]"
                    :precision="0"
                    :min="0"
                  />
                </b-col>
              </template>
              <!-- Per year LIABILITY -->
              <template v-if="accountType === 'liability'">
                <b-col cols="4" class="pr-2">
                  <currency-input
                    :ref="'CurrencyInputPerYearInterest' + year"
                    :id="'per-year-interest-' + year"
                    v-model="formData.perYear.interest[year]"
                    :precision="0"
                    :min="0"
                  />
                </b-col>
                <b-col cols="4">
                  <currency-input
                    :ref="'CurrencyInputPerYearAmortization' + year"
                    :id="'per-year-amortization-' + year"
                    v-model="formData.perYear.amortization[year]"
                    :precision="0"
                    :min="0"
                  />
                </b-col>
              </template>
            </b-row>
          </b-form-group>
        </template>
      </b-tab>

      <!-- /** PER MONTH TAB :: START **/ -->
      <b-tab :title="$t(translationPath + 'tabs.per_month')">
        <b-row no-gutters>
          <b-col cols="12"><p>{{ $t(translationPath + 'per_month.description') }}</p></b-col>
        </b-row>
        <b-form-group
          label-class="ml-1 pr-4 text-black"
          label-for="per-month-year"
          label-cols="3"
          required
          :label="$t(translationPath + 'per_month.select_year')"
        >
          <b-form-select
            id="per-month-year"
            v-model="formData.perMonth.year"
            :options="yearsRange"
            :disabled="!isValuesLoaded"
          />
        </b-form-group>

        <b-row no-gutters class="mt-4">
          <template v-if="isValuesLoaded">
            <b-col cols="3">
              <p class="ml-1">{{ $t(translationPath + 'per_month.month') }}</p>
            </b-col>

            <template v-if="accountType === 'asset'">
              <b-col cols="3"><p>{{ $t(translationPath + 'per_month.deposit') }}</p></b-col>
              <b-col cols="3"><p>{{ $t(translationPath + 'per_month.withdrawal') }}</p></b-col>
            </template>

            <template v-if="accountType === 'liability'">
              <b-col cols="3"><p>{{ $t(translationPath + 'per_month.interest') }}</p></b-col>
              <b-col cols="3"><p>{{ $t(translationPath + 'per_month.amortization') }}</p></b-col>
            </template>
            <b-col :cols="['income', 'expense'].indexOf(accountType) !== -1 ? 9 : 3">
              <p class="ml-1">{{ $t(translationPath + 'per_month.amount_per_month') }}</p>
            </b-col>
          </template>
        </b-row>

        <template v-if="!isValuesLoaded"><loader /></template>
        <template v-else>
          <b-form-group
            v-for="(amount, month) in formData.perMonth.amount[formData.perMonth.year]"
            :key="formData.perMonth.year.toString() + month.toString()"
            label-class="pr-4 text-black"
            :label-for="'per-month-amount-' + formData.perMonth.year + month"
            label-cols="3"
            required
            :label="formData.perMonth.year.toString() + '-' + month.toString().padStart(2, '0')"
          >
            <b-row no-gutters>
              <template v-if="accountType === 'asset'">
                <b-col cols="4" class="pr-2">
                  <currency-input
                    :ref="'CurrencyInputPerMonthDeposit' + formData.perMonth.year + month"
                    :id="'per-month-deposit-' + formData.perMonth.year + month"
                    v-model="formData.perMonth.deposit[formData.perMonth.year][month]"
                    :precision="0"
                    :min="0"
                    @input="calculateTotalPerMonthDeposit"
                  />
                </b-col>
                <b-col cols="4">
                  <currency-input
                    :ref="'CurrencyInputPerMonthWithdrawal' + formData.perMonth.year + month"
                    :id="'per-month-withdrawal-' + formData.perMonth.year + month"
                    v-model="formData.perMonth.withdrawal[formData.perMonth.year][month]"
                    :precision="0"
                    :min="0"
                    @input="calculateTotalPerMonthWithdrawal"
                  />
                </b-col>
              </template>

              <template v-if="accountType === 'liability'">
                <b-col cols="4" class="pr-2">
                  <currency-input
                    :ref="'CurrencyInputPerMonthInterest' + formData.perMonth.year + month"
                    :id="'per-month-interest-' + formData.perMonth.year + month"
                    v-model="formData.perMonth.interest[formData.perMonth.year][month]"
                    :precision="0"
                    :min="0"
                    @input="calculateTotalPerMonthInterest"
                  />
                </b-col>
                <b-col cols="4">
                  <currency-input
                    :ref="'CurrencyInputPerMonthAmortization' + formData.perMonth.year + month"
                    :id="'per-month-amortization-' + formData.perMonth.year + month"
                    v-model="formData.perMonth.amortization[formData.perMonth.year][month]"
                    :precision="0"
                    :min="0"
                    @input="calculateTotalPerMonthAmortization"
                  />
                </b-col>
              </template>

              <b-col
                :cols="['income', 'expense'].indexOf(accountType) !== -1 ? 12 : 4"
                :class="{ 'pl-2': ['income', 'expense'].indexOf(accountType) === -1 }"
              >
                <currency-input
                  v-if="['income', 'expense'].indexOf(accountType) !== -1"
                  :ref="'CurrencyInputPerMonthAmount' + formData.perMonth.year + month"
                  :id="'per-month-amount-' + formData.perMonth.year + month"
                  v-model="formData.perMonth.amount[formData.perMonth.year][month]"
                  :precision="0"
                  :min="0"
                  @input="calculateTotalPerMonthAmount"
                />
                <currency-input
                  v-else
                  :ref="'CurrencyInputPerMonthClosingBalance' + formData.perMonth.year + month"
                  :id="'per-month-amount-' + formData.perMonth.year + month"
                  v-model="formData.perMonth.amount[formData.perMonth.year][month]"
                  :precision="0"
                  :min="0"
                />
              </b-col>

            </b-row>
          </b-form-group>
        </template>
        <b-row no-gutters>
          <b-col cols="3">
            <p>{{ $t(translationPath + 'per_month.sum', { current_year: formData.perMonth.year }) }}</p>
          </b-col>
          <b-col v-if="['income', 'expense'].indexOf(accountType) !== -1" cols="9">
            <p>{{ formatAmount(totalPerMonthAmount) }} {{ currentCOA.currency_iso }}</p>
          </b-col>
          <b-col v-if="accountType === 'asset'" cols="3">
            <p>{{ formatAmount(totalPerMonthDeposit) }} {{ currentCOA.currency_iso }}</p>
          </b-col>
          <b-col v-if="accountType === 'asset'" cols="6">
            <p>{{ formatAmount(totalPerMonthWithdrawal) }} {{ currentCOA.currency_iso }}</p>
          </b-col>
          <b-col v-if="accountType === 'liability'" cols="3">
            <p>{{ formatAmount(totalPerMonthInterest) }} {{ currentCOA.currency_iso }}</p>
          </b-col>
          <b-col v-if="accountType === 'liability'" cols="6">
            <p>{{ formatAmount(totalPerMonthAmortization) }} {{ currentCOA.currency_iso }}</p>
          </b-col>

          <!--<b-col cols="12"><p>{{ $t(translationPath + 'per_month.sum',{ amount: formatAmount(totalPerMonthAmount), current_year: 2023, currency: currentCOA.currency_iso }) }}</p></b-col>-->
        </b-row>
      </b-tab>

      <!-- /** PASTE EXCEL TAB :: START **/ -->
      <b-tab :title="$t(translationPath + 'tabs.excel')">
        <b-row no-gutters>
          <b-col cols="12"><div v-html="$t(translationPath + 'excel.description')"></div></b-col>
        </b-row>
        <b-form-group
          label-cols="0"
          required
          label=""
          :invalid-feedback="excelDataInvalidFeedback"
        >
          <b-form-textarea
            v-model="formData.excel.data"
            rows="15"
            :placeholder="$t(translationPath + 'excel.data_placeholder_' + accountType)"
            :state="$v.formData.excel.data.$dirty ? !$v.formData.excel.data.$error : null"
          />
        </b-form-group>
      </b-tab>

      <b-tab :title="$t(translationPath + 'tabs.notes')">
        <loader v-if="!isNotesLoaded" />
        <b-form-group
          v-if="isNotesLoaded"
          label-cols="0"
          required
          label=""
          :invalid-feedback="notesInvalidFeedback"
        >
          <b-form-textarea
            v-model="formData.notes"
            rows="15"
            :placeholder="$t(translationPath + 'notes.placeholder')"
            :state="$v.formData.notes.$dirty ? !$v.formData.notes.$error : null"
          />
        </b-form-group>
      </b-tab>
    </b-tabs>

    <template slot="modal-footer" class="text-center">
      <b-row no-gutters class="w-100">
        <b-col cols="6" class="text-left">
          <button class="btn btn-sm btn-outline-secondary ml-4" @click.prevent="onDeleteAllAmounts">{{ $t(translationPath + 'delete_values' )}}</button>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button variant="secondary-outline" @click="onClose">{{ $t('common.cancel') }}</b-button>
          <b-button v-if="tabIndex !== 4" variant="primary" @click="onSetValues">{{ $t(translationPath + 'set_values') }}</b-button>
          <b-button v-if="tabIndex === 4" variant="primary" @click="onUpdateNotes">{{ $t(translationPath + 'notes.update_notes') }}</b-button>
        </b-col>
      </b-row>
    </template>

    <delete-modal
      ref="DeleteAmountsModal"
      :heading="$t(translationPath + 'confirm_delete_amounts_title')"
      :message="$t(translationPath + 'confirm_delete_amounts_text', { account_title: accountTitle })"
      @on-delete-confirm="deleteAllAmounts"
    />
  </b-modal>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'
import CurrencyInput from '@/components/common/CurrencyInput'
import PercentageInput from '@/components/common/PercentageInput'
import { required } from 'vuelidate/lib/validators'
import useComponentCurrencyInput from '@/mixins/useComponentCurrencyInput'
import DeleteModal from '@/components/modals/DeleteModal'

const isDateValid = getter => function (value) {
  if (!/^\d{4}-\d{2}$/.test(value)) {
    return false
  }
  const month = Number(value.substr(5))
  if (month > 12 || month === 0) {
    return false
  }
  return true
}

const isExcelDataValid = getter => function (value) {
  if (value === '') {
    return true
  }
  const lines = value.split('\n')
  if (lines.length === 0) {
    return true
  }

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    if (line === '') {
      continue
    }
    const parts = line.split(/;|\t/)
    let iNumberOfParts = null

    switch (this.accountType) {
      case 'asset':
        iNumberOfParts = 9
        break
      case 'liability':
        iNumberOfParts = 6
        break
      case 'income':
      case 'expense':
        iNumberOfParts = 3
        break
    }

    // Check number of parts
    if (parts.length !== iNumberOfParts) {
      return false
    }

    // Parts[0] will always be year YYYY
    if (!/^\d{4}$/.test(parts[0])) {
      return false
    }

    // Parts[1] will always be month M or MM
    if (!/^\d{1,2}$/.test(parts[1])) {
      return false
    }
    const month = Number(parts[1])
    if (month > 12 || month === 0) {
      return false
    }
    if (isNaN(parts[1])) {
      return false
    }

    switch (this.accountType) {
      case 'asset':
        /**
         * Asset excel import structure
         * 0: Year
         * 1: Month
         * 2: Deposit
         * 3: ROI (%)
         * 4: ROI (amount)
         * 5: Value before tax
         * 6: Tax (amount)
         * 7: Withdrawal
         * 8: Outgoing balance (amount)
         */
        for (let i = 2; i <= 8; i++) {
          parts[i] = parseExcelAmount(parts[i])
          if (isNaN(parts[i])) { return false }
        }

        break
      case 'liability':
        /**
         * Liability excel import structure  (length = 6)
         * 0: "Year"
         * 1: "Month"
         * 2: Interest (%)
         * 3: Interest amount
         * 4: Amortization
         * 5: Amount
         */
        for (let i = 2; i <= 5; i++) {
          parts[i] = parseExcelAmount(parts[i])
          if (isNaN(parts[i])) { return false }
        }

        break
      case 'income':
      case 'expense':
        /**
         * Liability excel import structure  (length = 6)
         * 0: "Year"
         * 1: "Month"
         * 2: Amount
         */
        parts[2] = parseExcelAmount(parts[2])
        if (isNaN(parts[2])) {
          return false
        }
        break
    }
  }
  return true
}

function parseExcelAmount (sAmount) {
  const noSpaces = sAmount.replace(/\s/g, '')
  const decimalDot = noSpaces.replace(/,/g, '.')
  const noCurrency = decimalDot.replace(/ [a-zA-Z]*$/, '')
  const noPercentage = noCurrency.replace(/%$/, '')
  return parseFloat(noPercentage)
}

export default {
  name: 'LifeplanBudgetReportCreateValuesModal',
  components: { Loader, CurrencyInput, PercentageInput, DeleteModal },
  mixins: [useComponentCurrencyInput],
  filter: { formatAmount },
  props: {
    accountType: {
      type: String,
      default: 'income'
    },
    accountTitle: {
      type: String,
      default: ''
    },
    budgetId: {
      type: [Number, String],
      default: 0
    },
    accountId: {
      type: [Number, String],
      default: 0
    },
    periodStart: {
      type: String,
      default: '2000-01'
    },
    periodEnd: {
      type: String,
      default: '2100-12'
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.lifeplan.create_values_modal.',
      tabIndex: 0,
      isValuesLoaded: false,
      isNotesLoaded: false,
      totalPerMonthAmount: 0,
      totalPerMonthDeposit: 0,
      totalPerMonthWithdrawal: 0,
      totalPerMonthInterest: 0,
      totalPerMonthAmortization: 0,
      formData: {
        period: {
          start: null,
          end: null,
          amount: 0,
          withdrawal: 0,
          deposit: 0,
          interest: 5,
          amortization_amount: 0,
          amortization_percentage: 2,
          amortization_type: 'percentage', // 'percentage' or 'amount
          starting_value: 0,
          roi: 7
        },
        perYear: { amount: {}, deposit: {}, withdrawal: {}, interest: {}, amortization: {} },
        perMonth: { year: (new Date()).getFullYear(), amount: {}, deposit: {}, withdrawal: {}, interest: {}, amortization: {} },
        excel: { data: '' },
        notes: ''
      }
    }
  },
  validations: {
    formData: {
      period: {
        start: { required, isDateValid: isDateValid() },
        end: { required, isDateValid: isDateValid() }
      },
      excel: {
        data: { required, isExcelDataValid: isExcelDataValid() }
      },
      notes: { required }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    yearsRange () {
      const years = []
      const start = parseInt(this.periodStart.split('-')[0])
      const end = parseInt(this.periodEnd.split('-')[0])

      for (let year = start; year <= end; year++) {
        years.push(year)
      }

      return years
    },
    periodStartInvalidFeedback () {
      if (this.$v.formData.period.start.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.formData.period.start.isDateValid === false) return this.$t(this.translationPath + 'errors.invalid_date_format')
      return ''
    },
    periodEndInvalidFeedback () {
      if (this.$v.formData.period.end.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.formData.period.end.isDateValid === false) return this.$t(this.translationPath + 'errors.invalid_date_format')
      return ''
    },
    excelDataInvalidFeedback () {
      if (this.$v.formData.excel.data.required === false) return this.$t(this.translationPath + 'errors.required')
      if (this.$v.formData.excel.data.isExcelDataValid === false) return this.$t(this.translationPath + 'errors.invalid_data_format')
      return ''
    },
    notesInvalidFeedback () {
      if (this.$v.formData.notes.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    }
  },
  created () {
    this.build()
  },
  methods: {
    onShow () {
      this.loadValues()
      this.loadNotes()
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    onHidden () {
      this.formData.notes = ''
      this.isNotesLoaded = false

      this.$emit('hidden')
    },
    onClose () {
      this.hide()
      this.$emit('close')
    },
    show () {
      this.$refs.Modal.show()
      this.$emit('show')
    },
    hide () {
      this.$v.$reset()
      this.$refs.Modal.hide()
      this.$emit('hide')
    },
    build () {
      this.formData.period.start = this.periodStart
      this.formData.period.end = this.periodEnd
      this.yearsRange.map(year => {
        this.formData.perYear.amount[year] = 0
        this.formData.perYear.deposit[year] = 0
        this.formData.perYear.withdrawal[year] = 0
        this.formData.perYear.interest[year] = 0
        this.formData.perYear.amortization[year] = 0
      })
      this.buildMonths()
    },
    buildMonths (copyFromYear = null) {
      const fields = ['amount', 'deposit', 'withdrawal', 'interest', 'amortization']

      const startYear = parseInt(this.periodStart.split('-')[0])
      const endYear = parseInt(this.periodEnd.split('-')[0])

      let startMonth = 1
      let endMonth = 12

      if (startYear === this.formData.perMonth.year) {
        startMonth = parseInt(this.periodStart.split('-')[1])
      } else if (endYear === this.formData.perMonth.year) {
        endMonth = parseInt(this.periodEnd.split('-')[1])
      }

      for (const i in fields) {
        const field = fields[i]
        if (Object.prototype.hasOwnProperty.call(this.formData.perMonth[field], this.formData.perMonth.year)) {
          continue
        }

        this.formData.perMonth[field][this.formData.perMonth.year] = {}

        for (let month = startMonth; month <= endMonth; month++) {
          if (copyFromYear !== null && Object.prototype.hasOwnProperty.call(this.formData.perMonth[field], copyFromYear)) {
            this.formData.perMonth[field][this.formData.perMonth.year][month] = this.formData.perMonth[field][copyFromYear][month] ?? 0
          } else {
            this.formData.perMonth[field][this.formData.perMonth.year][month] = 0
          }
        }
      }

      this.calculatePerMonthTotals()
    },
    async loadValues () {
      return new Promise((resolve, reject) => {
        if (!this.budgetId || !this.accountId) {
          resolve(true)
        } else {
          this.isValuesLoaded = false

          const startYear = this.yearsRange[0]
          const endYear = this.yearsRange[this.yearsRange.length - 1]
          const startMonth = parseInt(this.periodStart.split('-')[1])
          const endMonth = parseInt(this.periodEnd.split('-')[1])

          const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/budget/lifeplan/account/${this.accountId}?start_year=${startYear}&end_year=${endYear}`

          axios.get(apiEndpoint)
            .then(response => {
              this.yearsRange.map(year => {
                this.formData.perYear.amount[year] = Number(response.data.data.amount.years[year])
                if (!this.formData.perMonth.amount[year]) {
                  this.formData.perMonth.amount[year] = {}
                }

                switch (this.accountType) {
                  case 'asset':
                    if (!this.formData.perMonth.deposit[year]) {
                      this.formData.perMonth.deposit[year] = {}
                    }
                    if (!this.formData.perMonth.withdrawal[year]) {
                      this.formData.perMonth.withdrawal[year] = {}
                    }
                    break
                  case 'liability':
                    if (!this.formData.perMonth.interest[year]) {
                      this.formData.perMonth.interest[year] = {}
                    }
                    break
                }

                for (const month in response.data.data.amount.months[year]) {
                  if (
                    (year !== startYear && year !== endYear) ||
                    (year === startYear && Number(month) >= Number(startMonth)) ||
                    (year === endYear && Number(month) <= Number(endMonth))
                  ) {
                    this.formData.perMonth.amount[year][month] = Number(response.data.data.amount.months[year][month])
                    switch (this.accountType) {
                      case 'asset':
                        this.formData.perMonth.deposit[year][month] = Number(response.data.data.deposit.months[year][month])
                        this.formData.perMonth.withdrawal[year][month] = Number(response.data.data.withdrawal.months[year][month])
                        break
                      case 'liability':
                        this.formData.perMonth.interest[year][month] = Number(response.data.data.interest.months[year][month])
                    }
                  }
                }
              })
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.isValuesLoaded = true
              process.nextTick(() => {
                this.rerenderCurrencyInputs()
                this.calculatePerMonthTotals()
              })
            })
        }
      })
    },
    async loadNotes () {
      return new Promise((resolve, reject) => {
        if (!this.budgetId || !this.accountId) {
          resolve(true)
        } else {
          const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.budgetId}/${this.accountId}/simulation_notes`
          axios.get(apiEndpoint)
            .then(response => {
              if (response.data.data.simulation_notes) {
                this.formData.notes = response.data.data.simulation_notes.value
              } else {
                this.formData.notes = ''
              }
            })
            .finally(() => {
              this.isNotesLoaded = true
            })
        }
      })
    },
    onSetValues () {
      let iModifier = 0
      // if account is asset or liability, skip the first per year tab since it doesn not make sense and hence we need to modify the tab index
      if ((this.accountType === 'asset' || this.accountType === 'liability') && this.tabIndex !== 0) {
        iModifier = 1
      }
      const iModifiedTabIndex = this.tabIndex + iModifier
      switch (iModifiedTabIndex) {
        case 0:
          this.$v.formData.period.$touch()
          break
        case 3:
          this.$v.formData.excel.$touch()
          break
      }

      let data = {}
      switch (iModifiedTabIndex) {
        case 0: // per_period
          if (!this.$v.formData.period.$invalid) {
            data = {
              tab: 'period',
              account_id: this.accountId,
              account_type: this.accountType,
              start: this.formData.period.start,
              end: this.formData.period.end,
              amount: this.formData.period.amount
            }

            if (this.accountType === 'asset') {
              data.deposit = this.formData.period.deposit
              data.withdrawal = this.formData.period.withdrawal
              data.roi = this.formData.period.roi
              data.starting_value = this.formData.period.starting_value
            }

            if (this.accountType === 'liability') {
              data.interest_percentage_per_year = this.formData.period.interest
              data.amortization_percentage_per_year = this.formData.period.amortization_percentage
              data.amortization_amount_per_year = this.formData.period.amortization_amount
              data.amortization_type = this.formData.period.amortization_type
              data.starting_value = this.formData.period.starting_value
            }
            this.$emit('set-values', data)
          }
          break
        case 1:
          data = {
            tab: 'per_year',
            account_id: this.accountId,
            account_type: this.accountType,
            amount: this.formData.perYear.amount
          }
          if (this.accountType === 'asset') {
            data.deposit = this.formData.perYear.deposit
            data.withdrawal = this.formData.perYear.withdrawal
          }
          if (this.accountType === 'liability') {
            data.interest = this.formData.perYear.interest
            data.amortization = this.formData.perYear.amortization
          }
          this.$emit('set-values', data)
          break
        case 2: // per_month
          data = {
            tab: 'per_month',
            account_id: this.accountId,
            account_type: this.accountType,
            amount: {},
            years_range: this.yearsRange
          }
          if (this.accountType === 'asset') {
            data.deposit = {}
            data.withdrawal = {}
          }
          if (this.accountType === 'liability') {
            data.interest = {}
            data.amortization = {}
          }

          this.yearsRange.map(year => {
            data.amount[year] = this.formData.perMonth.amount[year]
            if (this.accountType === 'asset') {
              data.deposit[year] = this.formData.perMonth.deposit[year]
              data.withdrawal[year] = this.formData.perMonth.withdrawal[year]
            }
            if (this.accountType === 'liability') {
              data.interest[year] = this.formData.perMonth.interest[year]
              data.amortization[year] = this.formData.perMonth.amortization[year]
            }
          })

          this.$emit('set-values', data)
          break
        case 3:
          if (!this.$v.formData.excel.$invalid) {
            data = {
              tab: 'excel',
              account_id: this.accountId,
              account_type: this.accountType,
              data: this.formData.excel.data
            }
            this.$emit('set-values', data)
          }
          break
      }

      this.calculatePerMonthTotals()
    },
    onUpdateNotes () {
      this.$v.formData.notes.$touch()
      if (this.$v.formData.notes.$invalid) {
        return false
      }

      const data = {
        tab: 'notes',
        account_id: this.accountId,
        budget_id: this.budgetId,
        data: this.formData.notes
      }
      this.$emit('update-notes', data)
    },
    onDeleteAllAmounts () {
      this.$refs.DeleteAmountsModal.show()
    },
    deleteAllAmounts () {
      const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/delete-for-account/${this.budgetId}/${this.accountId}`
      axios.delete(apiEndpoint)
        .then(response => {
          this.$emit('values-deleted')
          this.$bvToast.toast(this.$t(this.translationPath + 'delete_amounts_toast_success', { account_title: this.accountTitle }).toString(), {
            title: this.$t(this.translationPath + 'delete_amounts_toast_title').toString(),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'delete_amounts_toast_fail', { account_title: this.accountTitle }).toString(), {
            title: this.$t(this.translationPath + 'delete_amounts_toast_title').toString(),
            variant: 'danger',
            solid: true
          })
        })
    },
    calculatePerMonthTotals () {
      switch (this.accountType) {
        case 'income':
        case 'expense':
          this.calculateTotalPerMonthAmount()
          break
        case 'asset':
          this.calculateTotalPerMonthDeposit()
          this.calculateTotalPerMonthWithdrawal()
          break
        case 'liability':
          this.calculateTotalPerMonthInterest()
          this.calculateTotalPerMonthAmortization()
          break
      }
    },
    calculateTotalPerMonthAmount () {
      this.totalPerMonthAmount = 0
      for (const month in this.formData.perMonth.amount[this.formData.perMonth.year]) {
        this.totalPerMonthAmount += Number(this.formData.perMonth.amount[this.formData.perMonth.year][month])
      }
    },
    calculateTotalPerMonthDeposit () {
      this.totalPerMonthDeposit = 0
      for (const month in this.formData.perMonth.deposit[this.formData.perMonth.year]) {
        this.totalPerMonthDeposit += Number(this.formData.perMonth.deposit[this.formData.perMonth.year][month])
      }
    },
    calculateTotalPerMonthWithdrawal () {
      this.totalPerMonthWithdrawal = 0
      for (const month in this.formData.perMonth.withdrawal[this.formData.perMonth.year]) {
        this.totalPerMonthWithdrawal += Number(this.formData.perMonth.withdrawal[this.formData.perMonth.year][month])
      }
    },
    calculateTotalPerMonthInterest () {
      this.totalPerMonthInterest = 0
      for (const month in this.formData.perMonth.interest[this.formData.perMonth.year]) {
        this.totalPerMonthInterest += Number(this.formData.perMonth.interest[this.formData.perMonth.year][month])
      }
    },
    calculateTotalPerMonthAmortization () {
      this.totalPerMonthAmortization = 0
      for (const month in this.formData.perMonth.amortization[this.formData.perMonth.year]) {
        this.totalPerMonthAmortization += Number(this.formData.perMonth.amortization[this.formData.perMonth.year][month])
      }
    },
    formatAmount (val) {
      return formatAmount(
        val,
        this.currentCOA.locale,
        this.currentCOA.currency,
        this.currentCOA.currency_iso,
        false,
        0
      )
    }
  },
  watch: {
    periodStart () {
      this.build()
    },
    periodEnd () {
      this.build()
    },
    'formData.perMonth.year' (newYear, oldYear) {
      this.buildMonths(oldYear)
      this.calculatePerMonthTotals()
    }
  }
}
</script>

<style lang="scss">
.lifeplan-budget-report-create-values-modal {
  .nav-tabs {
    a.nav-link {
      font-size: 1rem;
    }
  }
  .modal-footer {
    justify-content: unset;
  }
  label {
    font-size: 14px;
  }
  .invalid-feedback {
    font-size: 75%;
  }
}
</style>
