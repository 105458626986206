<template>
  <div class="component-reports-lifeplan-table-accounts">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" xl="4" class="mb-2">
                  <h2 v-if="['asset-withdrawal', 'asset-withdrawal-stacked-groups', 'asset-withdrawal-stacked-accounts'].indexOf(showChart) !== -1">
                    {{ $t(translationPath + 'title_' + accountType + '_withdrawals', { start_year: yearStart, end_year: yearEnd }) }}
                  </h2>
                  <h2 v-else-if="['asset-deposit', 'asset-deposit-stacked-groups', 'asset-deposit-stacked-accounts'].indexOf(showChart) !== -1">
                    {{ $t(translationPath + 'title_' + accountType + '_deposits', { start_year: yearStart, end_year: yearEnd }) }}
                  </h2>
                  <h2 v-else-if="['liability-interest', 'iability-interest-stacked-groups', 'iability-interest-stacked-accounts'].indexOf(showChart) !== -1">
                    {{ $t(translationPath + 'title_' + accountType + '_interest', { start_year: yearStart, end_year: yearEnd }) }}
                  </h2>
                  <h2 v-else-if="['liability-amortization', 'iability-amortization-stacked-groups', 'iability-amortization-stacked-accounts'].indexOf(showChart) !== -1">
                    {{ $t(translationPath + 'title_' + accountType + '_amortization', { start_year: yearStart, end_year: yearEnd }) }}
                  </h2>
                  <h2 v-else>
                    {{ $t(translationPath + 'title_' + accountType, { start_year: yearStart, end_year: yearEnd }) }}
                  </h2>
                </b-col>
                <b-col cols="12" xl="8" class="text-right">
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( 'reports.budget.lifeplan.charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('barchart')" v-bind:class="{ active: (showChart === 'barchart') }">{{$t('reports.budget.lifeplan.charts.barchart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('barchart-stacked-groups')" v-bind:class="{ active: (showChart === 'barchart-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.barchart-stacked-groups')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('barchart-stacked-accounts')" v-bind:class="{ active: (showChart === 'barchart-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.barchart-stacked-accounts')}}</b-dropdown-item>

                    <template v-if="accountType === 'asset'">
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('asset-withdrawal')" v-bind:class="{ active: (showChart === 'asset-withdrawal') }">{{$t('reports.budget.lifeplan.charts.asset-withdrawal')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-withdrawal-stacked-groups')" v-bind:class="{ active: (showChart === 'asset-withdrawal-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.asset-withdrawal-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-withdrawal-stacked-accounts')" v-bind:class="{ active: (showChart === 'asset-withdrawal-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.asset-withdrawal-stacked-accounts')}}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('asset-deposit')" v-bind:class="{ active: (showChart === 'asset-deposit') }">{{$t('reports.budget.lifeplan.charts.asset-deposit')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-deposit-stacked-groups')" v-bind:class="{ active: (showChart === 'asset-deposit-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.asset-deposit-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-deposit-stacked-accounts')" v-bind:class="{ active: (showChart === 'asset-deposit-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.asset-deposit-stacked-accounts')}}</b-dropdown-item>
                    </template>
                    <template v-if="accountType === 'liability'">
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('liability-interest')" v-bind:class="{ active: (showChart === 'liability-interest') }">{{$t('reports.budget.lifeplan.charts.liability-interest')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-interest-stacked-groups')" v-bind:class="{ active: (showChart === 'liability-interest-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.liability-interest-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-interest-stacked-accounts')" v-bind:class="{ active: (showChart === 'liability-interest-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.liability-interest-stacked-accounts')}}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('liability-amortization')" v-bind:class="{ active: (showChart === 'liability-amortization') }">{{$t('reports.budget.lifeplan.charts.liability-amortization')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-amortization-stacked-groups')" v-bind:class="{ active: (showChart === 'liability-amortization-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.liability-amortization-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-amortization-stacked-accounts')" v-bind:class="{ active: (showChart === 'liability-amortization-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.liability-amortization-stacked-accounts')}}</b-dropdown-item>
                    </template>

                  </b-dropdown>
                  <toggle-budgets-dropdown
                    v-if="budgets.length > 1"
                    ref="BudgetDropdown"
                    :budgets="budgets"
                    :initial-budget-id="budgetId"
                    @change="onBudgetChange"
                  />

                </b-col>
              </b-row>
            </template>

            <div v-if="['asset-withdrawal', 'asset-withdrawal-stacked-groups', 'asset-withdrawal-stacked-accounts'].indexOf(showChart) !== -1" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType + '_withdrawals')"></div>
            <div v-else-if="['asset-deposit', 'asset-deposit-stacked-groups', 'asset-deposit-stacked-accounts'].indexOf(showChart) !== -1" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType + '_deposits')"></div>
            <div v-else-if="['liability-interest', 'iability-interest-stacked-groups', 'iability-interest-stacked-accounts'].indexOf(showChart) !== -1" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType + '_interest')"></div>
            <div v-else-if="['liability-amortization', 'iability-amortization-stacked-groups', 'iability-amortization-stacked-accounts'].indexOf(showChart) !== -1" class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType + '_amortization')"></div>
            <div v-else class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType)"></div>

            <b-table
              :key="renderKey"
              class="spirecta-simple-table lifeplan-accounts-table mb-0"
              show-empty
              hover
              responsive
              striped
              :items="tableData"
              :fields="tableColumns"
              :filter="filter"
              :filter-included-fields="['title','title_with_code']"
              :busy="!isLoaded"
              :tbodyTrClass="tableRowSetClass"
            >
              <template v-slot:table-busy><loader /></template>

              <template slot="top-row">
                <td :colspan="(this.tableColumns.length)">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- title slot-->
              <template v-slot:cell(label)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-if="row.item.row_type === 'account'">
                  <span class="text-nowrap">
                    <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view/budget_simulation'">{{ row.item.label }}</b-link>
                    <b-link class="link link-set-values ml-1" href="#" @click="(event) => onSetValuesClick(event, row.item)" :title="$t(translationPath + 'set_values')"><i class="flaticon mr-1 solid edit-3 text-secondary"></i></b-link>
                  </span>
                </template>
                <template v-else-if="row.item.row_type === 'account_group'">
                  <b-link :id="'group-' + row.item.id" :to="'/reports/performance/account-groups/' + row.item.id + '/twelve-months-rolling'">
                    <strong>{{ row.item.label }} </strong>
                    <!--<span class="small">(x {{apiData.multiplier}}{{currentCOA.currency}})</span>-->
                </b-link>
                </template>
                <template v-else-if="row.item.row_type === 'part-sum'">
                  {{$t('common.partsum')}}
                </template>
                <template v-else-if="row.item.row_type === 'total'">
                  {{ row.value }}
                </template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(period)]="row" v-for="period in isLoaded ? this.apiData.meta.periods : []">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account_group'">{{row.unformatted}}</template>
                <template v-else-if="row.item.row_type === 'account'">
                  {{ row.value }}
                </template>
                <template v-else-if="row.item.row_type === 'part-sum'">
                  {{ row.value }}
                </template>
                <template v-else>
                  {{row.value}}
                </template>
              </template>

              <!-- total slot-->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- total_percentage slot-->
              <template v-slot:cell(total_percentage)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <lifeplan-budget-report-create-values-modal
      v-if="startDate && endDate"
      ref="SetValuesModal"
      :account-type="accountType"
      :account-title="accountToUpdate !== null ? accountToUpdate.title : ''"
      :account-id="accountToUpdate !== null ? accountToUpdate.id : 0"
      :budget-id="budgetId"
      :period-start="startDate.substr(0, 7)"
      :period-end="endDate.substr(0, 7)"
      @hide="onSetValuesModalHide"
      @set-values="onSetValues"
      @update-notes="onUpdateNotes"
      @values-deleted="onValuesDeleted"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'
import LifeplanBudgetReportCreateValuesModal from '../inc/LifeplanBudgetReportCreateValuesModal'

export default {
  name: 'LifeplanBudgetReportTableAccounts',
  mixins: [LifeplanBudgetReportMixin],
  components: { Loader, ToggleBudgetsDropdown, LifeplanBudgetReportCreateValuesModal },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    accountType: { type: String, default: 'income' },
    resolution: { type: String, default: 'month' },
    selectedChart: { type: String, default: 'barchart' },
    apiData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    budgets: {
      type: Array,
      default: function () {
        return []
      }
    },
    budgetId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.lifeplan.table.accounts.',
      showChart: 'result-bar-chart',
      tableData: [],
      tableColumns: [],
      filter: '',
      renderKey: 0,
      accountToUpdate: null,
      isSetValuesInProcess: false
    }
  },
  computed: {
    yearStart () {
      return this.startDate.substr(0, 4)
    },
    yearEnd () {
      return this.endDate.substr(0, 4)
    }
  },
  methods: {
    onColumnToggle (data) {
      this.$emit('column-toggle', data)
    },
    onSetValuesClick (event, item) {
      event.preventDefault()

      if (this.isSetValuesInProcess) {
        return false
      }

      this.accountToUpdate = {
        id: item.id,
        title: item.label
      }

      process.nextTick(() => {
        this.$refs.SetValuesModal.show()
      })
    },
    onSetValuesModalHide () {
      this.accountToUpdate = null
    },
    onSetValues (data) {
      this.isSetValuesInProcess = true
      const accountTitle = this.accountToUpdate.title
      this.$refs.SetValuesModal.hide()
      const postData = { data: this.prepareSetValuesData(data) }

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/mass-store-update`, postData)
        .then(response => {
          this.$emit('update')
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.set_values_success', { title: accountTitle }).toString(), {
            title: this.$t(this.translationPath + 'toast.set_values_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.set_values_fail', { title: accountTitle }).toString(), {
            title: this.$t(this.translationPath + 'toast.set_values_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .finally(() => {
          this.isSetValuesInProcess = false
        })
    },
    onValuesDeleted () {
      this.$emit('update')
    },
    onUpdateNotes (data) {
      this.isSetValuesInProcess = true
      const accountTitle = this.accountToUpdate.title
      // this.$refs.SetValuesModal.hide()

      const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${data.budget_id}/${data.account_id}/simulation_notes`
      axios.put(apiEndpoint, { option_value: data.data })
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_notes_success', { title: accountTitle }).toString(), {
            title: this.$t(this.translationPath + 'toast.update_notes_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_notes_fail', { title: accountTitle }).toString(), {
            title: this.$t(this.translationPath + 'toast.update_notes_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .finally(() => {
          this.isSetValuesInProcess = false
        })
    },
    prepareSetValuesData (source) {
      const data = []

      let startYear = null
      let startMonth = null
      let endYear = null
      let endMonth = null
      let lines = null
      let iOutgoingBalance = null
      let fMonthlyROI = null
      let fMonthlyLoanInterestPercentage = null
      let fMonthlyAmortizationPercentage = null
      let iIncomingBalance = null

      switch (source.tab) {
        case 'period':
          startYear = parseInt(source.start.substr(0, 4))
          startMonth = parseInt(source.start.substr(5, 2))
          endYear = parseInt(source.end.substr(0, 4))
          endMonth = parseInt(source.end.substr(5, 2))

          for (let y = startYear; y <= endYear; y++) {
            let firstMonth = 1
            let lastMonth = 12
            if (y === startYear) {
              firstMonth = startMonth
            }
            if (y === endYear) {
              lastMonth = endMonth
            }

            for (let m = firstMonth; m <= lastMonth; m++) {
              const item = {
                year: y,
                month: m,
                budget_id: this.budgetId,
                account_id: source.account_id,
                amount: source.amount,
                interest: null,
                withdrawal: null,
                deposit: null,
                tax: null,
                change_in_value: null,
                value_before_tax: null,
                opening_balance: null
              }

              // ASSET :: for asset accounts let's do a simple compounding interest calculation
              if (source.account_type === 'asset') {
                if (iOutgoingBalance === null) {
                  iOutgoingBalance = source.starting_value
                  iIncomingBalance = source.starting_value
                  fMonthlyROI = Math.pow((1 + source.roi / 100), (1 / 12)) - 1
                }
                item.opening_balance = iIncomingBalance
                item.change_in_value = (source.deposit + iIncomingBalance + source.deposit) * fMonthlyROI
                item.value_before_tax = iIncomingBalance + item.change_in_value + source.deposit

                if (item.value_before_tax - source.withdrawal > 0) {
                  iOutgoingBalance = item.value_before_tax - source.withdrawal
                } else {
                  source.withdrawal = item.value_before_tax
                  iOutgoingBalance = 0
                }

                item.amount = iOutgoingBalance.toFixed(0)
                item.deposit = source.deposit

                iIncomingBalance = iOutgoingBalance
              }
              // END ASSET

              // LIABILITY :: let's do a simple amortization / interest calculation
              if (source.account_type === 'liability') {
                if (iOutgoingBalance === null) {
                  iOutgoingBalance = source.starting_value
                  iIncomingBalance = source.starting_value
                  fMonthlyLoanInterestPercentage = Math.pow((1 + source.interest_percentage_per_year / 100), (1 / 12)) - 1
                  fMonthlyAmortizationPercentage = Math.pow((1 + source.amortization_percentage_per_year / 100), (1 / 12)) - 1
                }
                item.opening_balance = iIncomingBalance
                item.interest = Math.round(iOutgoingBalance * fMonthlyLoanInterestPercentage)

                // Deposit is alias for amortization
                if (source.amortization_type === 'percentage') {
                  item.deposit = Math.round(source.starting_value * fMonthlyAmortizationPercentage)
                } else if (source.amortization_type === 'amount') {
                  item.deposit = Math.round(source.amortization_amount_per_year / 12)
                }

                if (iOutgoingBalance - item.deposit >= 0) {
                  iOutgoingBalance = Math.round(iOutgoingBalance - item.deposit)
                } else {
                  item.deposit = iOutgoingBalance
                  iOutgoingBalance = 0
                }
                iIncomingBalance = iOutgoingBalance

                item.amount = iOutgoingBalance
              }
              // END LIABILITY

              data.push(item)
            }
          }
          break
        case 'per_year':
          for (const year in source.amount) {
            for (let m = 1; m <= 12; m++) {
              const item = {
                year: year,
                month: m,
                budget_id: this.budgetId,
                account_id: source.account_id,
                amount: Math.round(source.amount[year] / 12),
                interest: source.interest ? source.interest[year] : null,
                withdrawal: source.withdrawal ? source.withdrawal[year] : 0,
                deposit: source.amount[year],
                tax: 0,
                change_in_value: 0,
                value_before_tax: source.amount[year],
                opening_balance: 0
              }

              if (Object.prototype.hasOwnProperty.call(source, 'deposit')) {
                item.deposit = source.deposit[year]
              }
              if (Object.prototype.hasOwnProperty.call(source, 'amortization')) {
                item.deposit = source.amortization[year]
              }

              data.push(item)
            }
          }
          break
        case 'per_month':
          source.years_range.map(year => {
            for (const month in source.amount[year]) {
              const item = {
                year: year,
                month: parseInt(month),
                budget_id: this.budgetId,
                account_id: source.account_id,
                amount: source.amount[year][month],
                interest: source.interest ? source.interest[year][month] : null,
                withdrawal: source.withdrawal ? source.withdrawal[year][month] : 0,
                deposit: source.amount[year][month],
                tax: 0,
                change_in_value: 0,
                value_before_tax: source.amount[year][month],
                opening_balance: 0
              }

              if (Object.prototype.hasOwnProperty.call(source, 'deposit')) {
                item.deposit = source.deposit[year][month]
              }
              if (Object.prototype.hasOwnProperty.call(source, 'amortization')) {
                item.deposit = source.amortization[year][month]
              }

              data.push(item)
            }
          })

          break
        case 'excel':
          lines = (source.data.split('\n')).filter(line => line.length > 2)
          lines.map(line => {
            const cols = line.split(/;|\t/)
            const item = {
              year: parseInt(cols[0]),
              month: parseInt(cols[1]),
              budget_id: this.budgetId,
              account_id: source.account_id,
              amount: this.convertExcelAmountToNumber(cols[2]),
              deposit: null,
              tax: null,
              withdrawal: null,
              change_in_value: null,
              value_before_tax: null,
              opening_balance: null,
              interest: null
            }

            if (source.account_type === 'asset') {
              /**
               * Asset excel import structure (length = 9)
               * 0: Year
               * 1: Month
               * 2: Deposit
               * 3: ROI (%)
               * 4: ROI (amount)
               * 5: Value before tax
               * 6: Tax (amount)
               * 7: Withdrawal
               * 8: Outgoing balance (amount)
               */
              item.deposit = this.convertExcelAmountToNumber(cols[2])
              item.change_in_value = this.convertExcelAmountToNumber(cols[4])
              item.value_before_tax = this.convertExcelAmountToNumber(cols[5])
              item.tax = this.convertExcelAmountToNumber(cols[6])
              item.withdrawal = this.convertExcelAmountToNumber(cols[7])
              item.amount = this.convertExcelAmountToNumber(cols[8])
            } else if (source.account_type === 'liability') {
              /**
               * Liability excel import structure  (length = 6)
               * 0: "Year"
               * 1: "Month"
               * 2: Interest (%)
               * 3: Interest amount
               * 4: Amortization
               * 5: Amount
               */
              item.amount = this.convertExcelAmountToNumber(cols[5])
              item.interest = this.convertExcelAmountToNumber(cols[3])

              // Deposit is alias for amortization
              item.deposit = this.convertExcelAmountToNumber(cols[4])
            }

            data.push(item)
          })
          break
      }

      return data
    },
    convertExcelAmountToNumber (amount) {
      const noSpaces = amount.replace(/\s/g, '')
      const decimalDot = noSpaces.replace(/,/g, '.')
      const noCurrency = decimalDot.replace(/ [a-zA-Z]*$/, '')
      const number = parseFloat(noCurrency)
      return number
    },
    toggleChart (showChart) {
      this.$emit('chart-to-display', { from_tab: 'accounts', chart: showChart })
      this.showChart = showChart
      this.tableData = this.buildTableDataRows()
    },
    buildTableDataRows () {
      if (!this.isLoaded) {
        return []
      }

      let periodTotalsKey = 'period_totals'
      switch (this.showChart) {
        case 'asset-withdrawal' :
        case 'asset-withdrawal-stacked-groups' :
        case 'asset-withdrawal-stacked-accounts' :
          periodTotalsKey = 'withdrawals'
          break
        case 'asset-deposit':
        case 'asset-deposit-stacked-groups':
        case 'asset-deposit-stacked-accounts':
          periodTotalsKey = 'deposits'
          break
        case 'liability-interest':
        case 'liability-interest-stacked-groups':
        case 'liability-interest-stacked-accounts':
          periodTotalsKey = 'interest'
          break
        case 'liability-amortization':
        case 'liability-amortization-stacked-groups':
        case 'liability-amortization-stacked-accounts':
          periodTotalsKey = 'amortization'
          break
      }

      let bFirst = true
      const tableData = []

      const oEmptyRow = { row_type: 'empty-row', label: '' }
      let oPartSumRow = { label: this.$t('common.subresult'), row_type: 'subtotal' }

      const oTotalRow = Object.assign({ label: this.$t('reports.budget.lifeplan.table.summary.total_' + this.accountType), row_type: 'total' })

      let oAccountGroupRow = {}
      let oAccountRow = {}
      const oColumnLabels = {}

      // Create column label object to use for account group headers
      for (const index in this.apiData.meta.periods) {
        const sPeriod = this.apiData.meta.periods[index]
        oTotalRow[sPeriod] = this.apiData.totals.result.period_totals[sPeriod]
        oColumnLabels[sPeriod] = this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.apiData.meta.resolution)
      }
      oColumnLabels.total = this.$t('common.total')
      oColumnLabels.total_percentage = this.$t('common.total_percentage')

      // Loop all groups
      for (const iGroupId in this.apiData.groups[this.accountType]) {
        if (bFirst) {
          bFirst = false
        } else {
          tableData.push({ ...oPartSumRow })
          tableData.push(oEmptyRow)
        }

        // Create from API data
        oAccountGroupRow = Object.assign({ row_type: 'account_group', account_type: this.accountType }, this.apiData.groups[this.accountType][iGroupId])
        oAccountGroupRow = { ...oAccountGroupRow, ...oColumnLabels } // copy and merge

        // Finally add account group to table data array
        tableData.push({ ...oAccountGroupRow })

        // Loop all accounts in group
        for (const iAccountId in this.apiData.groups[this.accountType][iGroupId].accounts) {
          const oAccount = this.apiData.groups[this.accountType][iGroupId].accounts[iAccountId]
          oAccountRow = Object.assign({ row_type: 'account', account_type: this.accountType }, oAccount)

          // Loop all periods in account
          for (const index in oAccount[periodTotalsKey]) {
            oAccountRow[index] = oAccount[periodTotalsKey][index]
          }

          // Add to table
          tableData.push({ ...oAccountRow })
        }

        // Create from API data
        oPartSumRow = Object.assign({ label: this.$t('common.partsum'), row_type: 'part-sum' }, this.apiData.groups[this.accountType][iGroupId])

        // Loop all account-group period totals
        for (const index in this.apiData.groups[this.accountType][iGroupId][periodTotalsKey]) {
          oPartSumRow[index] = this.apiData.groups[this.accountType][iGroupId][periodTotalsKey][index]
        }
      }// end loop all groups

      // add last rows
      tableData.push({ ...oPartSumRow })
      tableData.push({ ...oEmptyRow })

      for (const index in this.apiData.totals[this.accountType][periodTotalsKey]) {
        oTotalRow[index] = this.apiData.totals[this.accountType][periodTotalsKey][index]
      }
      tableData.push({ ...oTotalRow })
      tableData.push({ ...oEmptyRow })

      this.renderKey++

      /**
       * Some minor fixes
       *  - change meta for summary table where we use account-groups as accounts for css to look nice
       *  - fix our fake group labels
       */
      for (const idx in tableData) {
        switch (tableData[idx].label) {
          case 'spirecta.withdrawals_group' :
            tableData[idx].label = this.$t('reports.budget.lifeplan.table.summary.withdrawal_from_assets')
            break
          case 'spirecta.withdrawals_account' :
            tableData[idx].label = this.$t('reports.budget.lifeplan.table.summary.withdrawal_from_assets')
            break
          case 'spirecta.interest_group' :
            tableData[idx].label = this.$t('reports.budget.lifeplan.table.summary.interest_from_liabilities')
            break
          case 'spirecta.interest_account' :
            tableData[idx].label = this.$t('reports.budget.lifeplan.table.summary.interest_from_liabilities')
            break
        }
      }

      return tableData
    }
  },
  mounted () {
    this.showChart = this.selectedChart
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableData = this.buildTableDataRows()
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: function () {
        this.tableData = this.buildTableDataRows()
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    accountType: {
      handler: function () {
        this.tableData = this.buildTableDataRows()
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    options: {
      handler: function () {
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    budgetId () {
      process.nextTick(() => {
        if (this.$refs.BudgetDropdown) {
          this.$refs.BudgetDropdown.$selectItem(this.budgetId)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.component-reports-lifeplan-table-accounts {
  .link-set-values {
    visibility: hidden;
    color: #dda3a2;
    font-size: 90%;
  }
  .lifeplan-accounts-table {
    tr:hover {
      .link-set-values {
        visibility: visible;
      }
    }
  }
}
</style>
