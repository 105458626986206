<template>
  <b-container fluid class="reports-budget-lifeplan-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-budget-lifeplan-bar-chart-id'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'

export default {
  name: 'LifeplanBudgetReportBarChart',
  components: { BarChart },
  mixins: [chartOptions, LifeplanBudgetReportMixin, defaultChartColorPalettes],
  props: {
    apiData: { type: Object },
    simulationType: { type: String, default: 'result-per-year' },
    currencySymbol: { type: String },
    resolution: { type: String },
    multiplier: { type: Number, default: 1 },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.budget.lifeplan.charts.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      this.chartOptions.legend.display = false
      this.chartOptions.title.display = true
      let tooltipLabel = null

      // Chart data
      const labels = []
      const chartData = []
      const backgroundColor = []
      let aPeriodDataSource = []
      let sDatasetLabel = ''
      let sPrimaryColor = this.getGreen()
      let sSecondaryColor = this.getRed()

      switch (this.simulationType) {
        case 'result-per-year':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.result')
          aPeriodDataSource = this.apiData.totals.result.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.result-per-year')
          tooltipLabel = this.$t('common.result') + ': '
          break
        case 'result-per-year-acc-chart':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.result_acc')
          aPeriodDataSource = this.apiData.totals.result.period_totals_acc
          sDatasetLabel = this.$t(this.translationPath + 'labels.result-per-year')
          tooltipLabel = this.$t('common.result') + ': '
          break
        case 'asset-withdrawal':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.asset-withdrawal')
          aPeriodDataSource = this.apiData.totals.asset.withdrawals
          sDatasetLabel = this.$t(this.translationPath + 'labels.withdrawal')
          tooltipLabel = this.$t('common.withdrawal') + ': '
          sPrimaryColor = this.getYellow()
          break
        case 'asset-deposit':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.asset-deposit')
          aPeriodDataSource = this.apiData.totals.asset.deposits
          sDatasetLabel = this.$t(this.translationPath + 'labels.deposit')
          tooltipLabel = this.$t('common.deposit') + ': '
          break
        case 'balance-per-year':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.networth')
          aPeriodDataSource = this.apiData.totals.networth.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'titles.networth')
          tooltipLabel = this.$t('common.networth') + ': '
          sPrimaryColor = this.getYellow()
          sSecondaryColor = this.getYellow()
          break
        case 'income':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.income')
          aPeriodDataSource = this.apiData.totals.income.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.income')
          tooltipLabel = this.$t('common.incomes') + ': '
          break
        case 'expense':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.expense')
          aPeriodDataSource = this.apiData.totals.expense.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.expense')
          tooltipLabel = this.$t('common.expenses') + ': '
          sPrimaryColor = this.getRed()
          sSecondaryColor = this.getRed()
          break
        case 'asset':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.asset')
          aPeriodDataSource = this.apiData.totals.asset.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.asset')
          tooltipLabel = this.$t('common.assets') + ': '
          sPrimaryColor = this.getYellow()
          sSecondaryColor = this.getYellow()
          break
        case 'liability':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.liability')
          aPeriodDataSource = this.apiData.totals.liability.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'titles.liability')
          tooltipLabel = this.$t('common.liability') + ': '
          sPrimaryColor = this.getPurple()
          sSecondaryColor = this.getPurple()
          break
        case 'liability-interest':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.interest')
          aPeriodDataSource = this.apiData.totals.liability.interest
          sDatasetLabel = this.$t(this.translationPath + 'labels.interest')
          tooltipLabel = this.$t('common.interest') + ': '
          sPrimaryColor = this.getRed()
          sSecondaryColor = this.getRed()
          break
        case 'liability-amortization':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.amortization')
          aPeriodDataSource = this.apiData.totals.liability.amortization
          sDatasetLabel = this.$t(this.translationPath + 'labels.amortization')
          tooltipLabel = this.$t('common.amortization') + ': '
          break
        case 'cashflow-per-year-chart':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.cashflow')
          aPeriodDataSource = this.apiData.totals.cashflow.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.cashflow')
          tooltipLabel = this.$t(this.translationPath + 'labels.cashflow_short') + ': '
          break
        case 'cashflow-acc-chart':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.cashflow_acc')
          aPeriodDataSource = this.apiData.totals.cashflow.period_totals_acc
          sDatasetLabel = this.$t(this.translationPath + 'labels.cashflow')
          tooltipLabel = this.$t(this.translationPath + 'labels.cashflow_short') + ': '
          break
      }

      let sSuffix = null

      for (const sPeriod in aPeriodDataSource) {
        let label = this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution)

        if (this.apiData.meta.birth_year !== null && this.resolution === 'year') {
          const age = parseInt(sPeriod) - parseInt(this.apiData.meta.birth_year)
          sSuffix = age + ' ' + this.$t('common.years').toLowerCase()
          label = [label, sSuffix]
        }

        labels.push(label)
        chartData.push((aPeriodDataSource[sPeriod] * this.multiplier).toFixed(0))
        backgroundColor.push(aPeriodDataSource[sPeriod] >= 0 ? sPrimaryColor : sSecondaryColor)
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: sDatasetLabel,
            data: chartData,
            type: 'bar',
            backgroundColor: backgroundColor
          }
        ]
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return tooltipLabel + ' ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.tooltips.callbacks.title = function (tooltipItem) {
        const arrayWithoutSpaces = tooltipItem[0].label.toString().split(',')
        return arrayWithoutSpaces.join(', ')
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
