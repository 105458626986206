<template>
  <div class="component-reports-long-term-performance-budget">
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper pb-2">
        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}
                <b-badge v-if="isFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="isFilterReady"
                scope="long-term-budget"
                :default-period-type="'next-30-years'"
                :allowed-resolutions="['year', 'month']"
                :default-resolution="resolution"
                :default-start-date="periodStart ? $moment(periodStart).format('YYYY-MM-DD') : null"
                :default-end-date="periodStart ? $moment(periodEnd).format('YYYY-MM-DD') : null"
                :showResolution="true"
                :show-predefined-periods="false"
                show-predefined-long-term-periods
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters :class="{'pb-3': activeTab === 'filter'}">
          <b-col no-gutters>
            <lifeplan-budget-report-submenu ref="Submenu" class="overview" :active="activeTab" @tab-switched="onTabSwitched"/>
          </b-col>
        </b-row>
      </b-container>

      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5 charts-subwrapper" v-if="activeTab !== 'filter'" >

          <!--
          /*********************************************
          * SUMMARY TAB CHARTS                         *
          *********************************************/
           -->

          <!-- Income vs expenses (stepped) #income-vs-expenses-stepped-chart -->
          <lifeplan-report-chart-stepped
            v-if="activeTab === 'summary' && ( summaryTabChartToDisplay === 'income-vs-expenses-stepped-chart' || summaryTabChartToDisplay === 'cashflow-stepped-chart')"
            :simulation-type="summaryTabChartToDisplay"
            :key="renderKey"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <!-- Result per year bar chart (income - expenses) #result-per-year -->
          <lifeplan-report-bar-chart
            v-if="activeTab === 'summary' && ( summaryTabChartToDisplay === 'result-per-year' || summaryTabChartToDisplay === 'balance-per-year' || summaryTabChartToDisplay === 'cashflow-per-year-chart' || summaryTabChartToDisplay === 'cashflow-acc-chart' || summaryTabChartToDisplay === 'result-per-year-acc-chart')"
            :simulation-type="summaryTabChartToDisplay"
            :key="renderKey"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <lifeplan-report-bar-chart-grouped
            v-if="activeTab === 'summary' && ( summaryTabChartToDisplay === 'income-vs-expenses-grouped-chart' || summaryTabChartToDisplay === 'assets-vs-liabilities-grouped-chart' )"
            :key="renderKey"
            :simulation-type="summaryTabChartToDisplay"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <!--
          /*********************************************
          * ACCOUNT TYPE TAB CHARTS                    *
          *********************************************/
           -->
          <!-- ACCOUNT TOTAL -->
          <lifeplan-report-bar-chart
            :key="renderKey"
            :simulation-type="activeTab"
            v-if="isAccountTab && accountTypeTabChartToDisplay === 'barchart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <!-- ACCOUNT GROUP / CATEGORY -->
          <lifeplan-report-bar-chart-stacked
            lifeplan-budget-report-chart-account-type-stacked
            :key="renderKey"
            :simulation-type="activeTab"
            v-if="isAccountTab && ( accountTypeTabChartToDisplay === 'barchart-stacked-groups' || accountTypeTabChartToDisplay === 'barchart-stacked-accounts') "
            :typeOfChartGroupOrAccount="accountTypeTabChartToDisplay"
            :activeTab="activeTab"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <!-- Special charts :: ASSET -->
          <lifeplan-report-bar-chart
            v-if="activeTab === 'asset' && ( accountTypeTabChartToDisplay === 'asset-withdrawal' || accountTypeTabChartToDisplay === 'asset-deposit')"
            :simulation-type="accountTypeTabChartToDisplay"
            :key="renderKey"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <lifeplan-report-bar-chart-stacked
            lifeplan-budget-report-chart-account-type-stacked
            :key="renderKey"
            :simulation-type="activeTab"
            v-if="isAccountTab && (accountTypeTabChartToDisplay === 'asset-withdrawal-stacked-groups' || accountTypeTabChartToDisplay === 'asset-withdrawal-stacked-accounts')"
            :typeOfChartGroupOrAccount="accountTypeTabChartToDisplay"
            :activeTab="activeTab"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <lifeplan-report-bar-chart-stacked
            lifeplan-budget-report-chart-account-type-stacked
            :key="renderKey"
            :simulation-type="activeTab"
            v-if="isAccountTab && (accountTypeTabChartToDisplay === 'asset-deposit-stacked-groups' || accountTypeTabChartToDisplay === 'asset-deposit-stacked-accounts')"
            :typeOfChartGroupOrAccount="accountTypeTabChartToDisplay"
            :activeTab="activeTab"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <!-- Special charts :: LIABILITY -->
          <lifeplan-report-bar-chart
            v-if="activeTab === 'liability' && (accountTypeTabChartToDisplay === 'liability-interest' || accountTypeTabChartToDisplay === 'liability-amortization')"
            :simulation-type="accountTypeTabChartToDisplay"
            :key="renderKey"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />

          <lifeplan-report-bar-chart-stacked
            lifeplan-budget-report-chart-account-type-stacked
            :key="renderKey"
            :simulation-type="activeTab"
            v-if="isAccountTab && (['liability-interest-stacked-groups', 'liability-interest-stacked-accounts', 'liability-amortization-stacked-groups', 'liability-amortization-stacked-accounts'].indexOf(accountTypeTabChartToDisplay) !== -1)"
            :typeOfChartGroupOrAccount="accountTypeTabChartToDisplay"
            :activeTab="activeTab"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="isLoaded"
            :resolution="this.resolution"
            :multiplier="1000"
          />
        </div>
      </b-row>
    </b-container>

    <b-container class="main-content-wrapper pt-0">
      <!-- TABLES -->
      <lifeplan-budget-report-table-overview v-if="activeTab === 'summary'"
        ref="Tablesummary"
        :activeTab="activeTab"
        :apiData="apiData"
        :isLoaded="isLoaded"
        :options="toggleColumnsOptions"
        :selected-chart="summaryTabChartToDisplay"
        :budgets="budgets"
        :budgetId="budgetId"
        @click-title="onTabChanged"
        @column-toggle="onColumnToggle"
        @chart-to-display="onChartChange"
        @budget-change="onBudgetChange"
      />

      <lifeplan-budget-report-table-accounts v-if="activeTab === 'income'"
        ref="Tableincome"
        :accountType="'income'"
        :apiData="apiData"
        :startDate="sendDateAsParam('periodStart')"
        :endDate="sendDateAsParam('periodEnd')"
        :isLoaded="isLoaded"
        :resolution="this.resolution"
        :options="toggleColumnsOptions"
        :selected-chart="accountTypeTabChartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @column-toggle="onColumnToggle"
        @chart-to-display="onChartChange"
        @budget-change="onBudgetChange"
        @update="onValuesUpdated"
      />

      <lifeplan-budget-report-table-accounts v-if="activeTab === 'expense'"
        ref="Tableexpense"
        :accountType="'expense'"
        :apiData="apiData"
        :startDate="sendDateAsParam('periodStart')"
        :endDate="sendDateAsParam('periodEnd')"
        :isLoaded="isLoaded"
        :resolution="this.resolution"
        :options="toggleColumnsOptions"
        :selected-chart="accountTypeTabChartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @column-toggle="onColumnToggle"
        @chart-to-display="onChartChange"
        @budget-change="onBudgetChange"
        @update="onValuesUpdated"
      />

      <lifeplan-budget-report-table-accounts v-if="activeTab === 'asset'"
        ref="Tableasset"
        :accountType="'asset'"
        :apiData="apiData"
        :startDate="sendDateAsParam('periodStart')"
        :endDate="sendDateAsParam('periodEnd')"
        :isLoaded="isLoaded"
        :resolution="this.resolution"
        :options="toggleColumnsOptions"
        :selected-chart="accountTypeTabChartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @column-toggle="onColumnToggle"
        @chart-to-display="onChartChange"
        @budget-change="onBudgetChange"
        @update="onValuesUpdated"
      />

      <lifeplan-budget-report-table-accounts v-if="activeTab === 'liability'"
        ref="Tableliability"
        :accountType="'liability'"
        :apiData="apiData"
        :startDate="sendDateAsParam('periodStart')"
        :endDate="sendDateAsParam('periodEnd')"
        :isLoaded="isLoaded"
        :resolution="this.resolution"
        :options="toggleColumnsOptions"
        :selected-chart="accountTypeTabChartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @column-toggle="onColumnToggle"
        @chart-to-display="onChartChange"
        @budget-change="onBudgetChange"
        @update="onValuesUpdated"
      />

      <report-filter
        v-show="activeTab === 'filter'"
        ref="ReportFilter"
        cache-state
        :filter-data="reportFilterData"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
        @ready="isFilterReady = true"
        @column-toggle="onColumnToggle"
      />
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import titleMixins from '@/mixins/title'
import LifeplanBudgetReportSubmenu from './inc/LifeplanBudgetReportSubmenu'
import PeriodSelector from '@/components/common/PeriodSelector'
import ReportFilter from '@/components/common/ReportFilter'
import LifeplanBudgetReportTableOverview from './tables/LifeplanBudgetReportTableOverview'
import LifeplanBudgetReportTableAccounts from './tables/LifeplanBudgetReportTableAccounts'
import LifeplanReportBarChart from './charts/LifeplanReportBarChart'
import LifeplanReportBarChartGrouped from './charts/LifeplanReportBarChartGrouped'
import LifeplanReportBarChartStacked from './charts/LifeplanReportBarChartStacked'
import LifeplanReportChartStepped from './charts/LifeplanReportChartStepped'

export default {
  name: 'LifePlanBudgetReport',
  mixins: [titleMixins],
  components: {
    LifeplanBudgetReportSubmenu,
    PeriodSelector,
    ReportFilter,
    LifeplanBudgetReportTableOverview,
    LifeplanBudgetReportTableAccounts,
    LifeplanReportBarChart,
    LifeplanReportBarChartGrouped,
    LifeplanReportBarChartStacked,
    LifeplanReportChartStepped
  },
  data () {
    return {
      activeTab: 'summary',
      translationPath: 'reports.budget.lifeplan.',
      allowedTabNames: ['summary', 'income', 'expense', 'asset', 'liability', 'filter'],
      isFilterApplied: false,
      isFilterReady: false,
      periodStart: null,
      periodEnd: null,
      resolution: 'year',
      reportFilterData: null,
      includeAccounts: '',
      isLoaded: false,
      apiData: null,
      budgetId: null,
      budgets: [],
      toggleColumnsOptions: {
        total: { label: 'common.total', isActive: true },
        total_percentage: { label: 'common.total_percentage', isActive: true }
      },
      summaryTabChartToDisplay: 'cashflow-per-year-chart',
      accountTypeTabChartToDisplay: 'barchart',
      renderKey: 0,
      tabChartState: { summary: '', income: '', expense: '', asset: '', liability: '' }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('incomes_expenses.submenu.budget'), to: '/reports/budget' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    },
    isAccountTab () {
      return ['income', 'expense', 'asset', 'liability'].indexOf(this.activeTab) !== -1
    }
  },
  methods: {
    async loadData (backgroundLoad = false) {
      return new Promise((resolve, reject) => {
        if (!this.periodStart || !this.periodEnd || !this.budgetId) {
          resolve(true)
        } else {
          if (!backgroundLoad) {
            this.isLoaded = false
          }

          let apiUrl = `${process.env.VUE_APP_ROOT_API}/reports/budget/lifeplan?budget_id=${this.budgetId}&start_date=${moment(this.periodStart).format('YYYY-MM')}&end_date=${moment(this.periodEnd).format('YYYY-MM')}&resolution=${this.resolution}`
          if (this.includeAccounts) {
            apiUrl += this.includeAccounts
          }

          axios.get(apiUrl)
            .then(response => {
              this.apiData = response.data.data
              resolve(this.apiData)
            })
            .catch(err => {
              console.error(err)
              reject(err)
            })
            .finally(() => {
              this.isLoaded = true
            })
        }
      })
    },
    async loadBudgets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.budgets = response.data.data
            resolve(this.budgets)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onTabSwitched (data) {
      this.activeTab = data.tab
      if (data.tab !== this.$route.params.tab) {
        if (data.tab === 'summary') {
          this.summaryTabChartToDisplay = this.tabChartState.summary
          process.nextTick(() => {
            this.$refs.Tablesummary.showChart = this.summaryTabChartToDisplay
            this.$refs.Tablesummary.makeRows()
          })
        } else if (['income', 'expense', 'asset', 'liability'].indexOf(data.tab) !== -1) {
          this.accountTypeTabChartToDisplay = this.tabChartState[data.tab]
          process.nextTick(() => {
            this.$refs['Table' + data.tab].showChart = this.accountTypeTabChartToDisplay
          })
        }

        this.renderKey++
        this.$router.push({ params: { tab: data.tab }, query: this.$route.query })
        this.setPageTitle(this.titleMeta)

        if (data.chart) {
          process.nextTick(() => {
            this.$refs['Table' + data.tab].toggleChart(data.chart)
          })
        }
      }
    },
    onPeriodChange (data) {
      this.periodStart = data.oDateStart
      this.periodEnd = data.oDateEnd
      this.resolution = data.resolution
      const routeQuery = {
        start_date: moment(this.periodStart).format('YYYY-MM-DD'),
        end_date: moment(this.periodEnd).format('YYYY-MM-DD')
      }
      if (routeQuery.start_date !== this.$route.query.start_date || routeQuery.end_date !== this.$route.query.end_date) {
        this.$router.push({ params: this.$route.params, query: routeQuery })
      }

      this.loadData()
    },
    onFilterApplied (filteredAccounts) {
      this.includeAccounts = filteredAccounts
      this.isFilterApplied = true
      this.loadData()
    },
    onFilterDeleted () {
      this.isFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.includeAccounts = ''
      this.isFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
    },
    sendDateAsParam (value) {
      if (!this.isLoaded) {
        return ''
      }
      return moment(this[value]).format('YYYY-MM-DD')
    },
    onTabChanged (data) {
      this.$refs.Submenu.changeTab(data)
      this.setPageTitle(this.titleMeta)
    },
    onColumnToggle (data) {
      this.toggleColumnsOptions = data.columns
    },
    onChartChange (data) {
      switch (data.from_tab) {
        case 'summary':
          this.summaryTabChartToDisplay = data.chart
          break
        case 'accounts':
          this.accountTypeTabChartToDisplay = data.chart
          break
      }
      this.tabChartState[this.activeTab] = data.chart
      this.renderKey++
    },
    onBudgetChange (budgetId) {
      this.budgetId = budgetId
      this.loadData()
    },
    onValuesUpdated () {
      this.loadData(true)
    }
  },
  mounted () {
    this.loadBudgets()

    if (this.$route.query.budget_id) {
      this.budgetId = this.$route.query.budget_id
    }
    if (!this.budgetId && this.currentCOA.default_budget_id) {
      this.budgetId = this.currentCOA.default_budget_id
    }

    if (this.$route.query.end_date) {
      this.periodEnd = new Date(this.$route.query.end_date)
    }
    if (this.$route.query.start_date) {
      this.periodStart = new Date(this.$route.query.start_date)
    }

    if (this.allowedTabNames.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.activeTab = 'result'
      this.$router.push({ params: { tab: 'result' }, query: this.$route.query })
    }

    this.$refs.Submenu.setActiveTabValue(this.activeTab)
    this.tabChartState.summary = this.summaryTabChartToDisplay
    this.tabChartState.income = this.accountTypeTabChartToDisplay
    this.tabChartState.expense = this.accountTypeTabChartToDisplay
    this.tabChartState.asset = this.accountTypeTabChartToDisplay
    this.tabChartState.liability = this.accountTypeTabChartToDisplay
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      if (this.allowedTabNames.indexOf(this.$route.params.tab) !== -1) {
        this.onTabSwitched({ tab: this.$route.params.tab })
      }
    },
    'currentCOA.default_budget_id' () {
      if (!this.budgetId) {
        this.budgetId = this.currentCOA.default_budget_id
      }
    }
  }
}
</script>

<style lang="scss">
.component-reports-long-term-performance-budget {
  width: 100%;
  .charts-subwrapper {
    width: 100%;
    height: 500px;
  }

  .component-reports-long-term-performance-budget-table-result, .component-reports-lifeplan-table-accounts {
    thead {
      display: none;
    }
    td {
      font-size: 90%;
    }

    tr.tr-account-group {
      td {
        background: white;
        border-top: 0;
        padding-top: 15px;
        border-bottom: 1px solid #949191;
        font-size: 90%;
      }
      td:first-child {
        font-size: 110%;
      }
    }

    tr.tr-account {
      td:first-child {
        font-weight: 400
      }
    }

    tr.tr-part-sum td {
      font-weight: 900;
      border-top: 1px solid #949191;
      border-bottom: 1px solid #949191;
    }

    tr.tr-empty-row td {
      background: white;
    }

    tr.tr-total{
      td {
        font-weight: 900;
        border-top: 1px solid black;
        border-bottom: 2px solid black;
      }
    }

    tr.tr-total-acc {
      td {
        border-bottom: 2px solid black;
      }
    }

    /* Columns */
    td.td-total-column {
      border-left: 1px solid black;
    }
    td.td-title {
      min-width: 250px;
    }
  }
}
</style>
