<template>
  <b-container fluid class="component-lifeplan-report-chart-stepped">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'lifeplan-report-chart-stepped'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'

export default {
  name: 'LifeplanReportChartStepped',
  components: { LineChart },
  mixins: [chartOptions, LifeplanBudgetReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    simulationType: { type: String, default: 'result-per-year' },
    multiplier: { type: Number, default: 1 },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.budget.lifeplan.charts.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      let tooltipLabelDataset01 = this.$t('common.incomes')
      let tooltipLabelDataset02 = this.$t('common.expenses')
      this.chartOptions.legend.display = true
      this.chartOptions.title.display = true
      let aDataset01Data = {}
      let aDataset02Data = {}

      switch (this.simulationType) {
        case 'income-vs-expenses-stepped-chart':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.income-vs-expenses-stepped')
          tooltipLabelDataset01 = this.$t('common.incomes')
          tooltipLabelDataset02 = this.$t('common.expenses')
          aDataset01Data = this.apiData.totals.income.period_totals
          aDataset02Data = this.apiData.totals.expense.period_totals
          break
        case 'cashflow-stepped-chart':
          this.chartOptions.title.text = this.$t(this.translationPath + 'titles.cashflow-stepped-chart')
          tooltipLabelDataset01 = this.$t(this.translationPath + 'labels.cashflow_incoming')
          tooltipLabelDataset02 = this.$t(this.translationPath + 'labels.cashflow_outgoing')
          aDataset01Data = this.apiData.totals.cashflow.incoming
          aDataset02Data = this.apiData.totals.cashflow.outgoing
          break
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return (tooltipItem.datasetIndex === 0 ? tooltipLabelDataset02 : tooltipLabelDataset01) + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      // Chart data
      const labels = []

      const aDataset01 = {
        label: tooltipLabelDataset01,
        data: [],
        type: 'bar',
        backgroundColor: 'rgba(140, 204, 173,0.7)'
      }

      const aDataset02 = {
        label: tooltipLabelDataset02,
        data: [],
        type: 'line',
        backgroundColor: 'rgba(225, 165, 160,0.5)',
        borderColor: 'rgba(225, 165, 160,1)',
        steppedLine: 'left',
        fill: true
      }

      for (const sPeriodIdx in this.apiData.meta.periods) {
        const sPeriod = this.apiData.meta.periods[sPeriodIdx]

        let label = this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution)

        if (this.apiData.meta.birth_year !== null && this.resolution === 'year') {
          const age = parseInt(sPeriod) - parseInt(this.apiData.meta.birth_year)
          label = [label, age + ' ' + this.$t('common.years').toLowerCase()]
        }

        labels.push(label)

        aDataset01.data.push((aDataset01Data[sPeriod] * this.multiplier).toFixed(0))
        aDataset02.data.push((aDataset02Data[sPeriod] * this.multiplier).toFixed(0))
      }

      this.chartData = {
        labels: labels,
        datasets: [aDataset02, aDataset01]
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
