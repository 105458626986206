<template>
  <b-container fluid class="component-performance-over-time-report-chart-account-type-stacked">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'performance-over-time-report-chart-account-type-stacked'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'

export default {
  name: 'LifeplanReportBarChartStacked',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, LifeplanBudgetReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    multiplier: { type: Number, default: 1 },
    activeTab: { type: String, default: 'income' },
    typeOfChartGroupOrAccount: { type: String, default: 'barchart-stacked-groups' },
    isLoaded: { type: Boolean, default: false },
    simulationType: { type: String, default: 'performance' }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      const aDatasets = []
      const labels = []
      const oPalette = this.getChartPalette()

      this.chartOptions.legend.display = true
      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.xAxes[0].stacked = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      // Labels
      for (const sPeriodIdx in this.apiData.meta.periods) {
        const sPeriod = this.apiData.meta.periods[sPeriodIdx]
        let label = this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution)

        if (this.apiData.meta.birth_year !== null && this.resolution === 'year') {
          const age = parseInt(sPeriod) - parseInt(this.apiData.meta.birth_year)
          label = [label, age + ' ' + this.$t('common.years').toLowerCase()]
        }

        labels.push(label)
      }

      /**
       * HANDLE ACCOUNT GROUPS
       */
      if (
        this.typeOfChartGroupOrAccount === 'barchart-stacked-groups' ||
        this.typeOfChartGroupOrAccount === 'asset-withdrawal-stacked-groups' ||
        this.typeOfChartGroupOrAccount === 'asset-deposit-stacked-groups' ||
        this.typeOfChartGroupOrAccount === 'liability-interest-stacked-groups' ||
        this.typeOfChartGroupOrAccount === 'liability-amortization-stacked-groups'
      ) {
        for (const iGroupIdx in this.apiData.groups[this.activeTab]) {
          const oGroup = this.apiData.groups[this.activeTab][iGroupIdx]
          const oTmpDataset = {}
          let iSum = 0

          oTmpDataset.label = oGroup.label
          oTmpDataset.data = []
          let aPeriodValues = []

          switch (this.typeOfChartGroupOrAccount) {
            case 'barchart-stacked-groups':
              aPeriodValues = oGroup.period_totals
              break
            case 'asset-withdrawal-stacked-groups':
              aPeriodValues = oGroup.withdrawals
              break
            case 'asset-deposit-stacked-groups':
              aPeriodValues = oGroup.deposits
              break
            case 'liability-interest-stacked-groups':
              aPeriodValues = oGroup.interest
              break
            case 'liability-amortization-stacked-groups':
              aPeriodValues = oGroup.amortization
              break
          }

          for (const sPeriod in aPeriodValues) {
            oTmpDataset.data.push((aPeriodValues[sPeriod] * this.multiplier).toFixed(0))
            iSum += aPeriodValues[sPeriod]
          }

          // Only display categories with non-zero result
          if (iSum !== 0) {
            oTmpDataset.backgroundColor = oPalette.shift()
            aDatasets.push(oTmpDataset)
          }
        }
      }

      /**
       * HANDLE ACCOUNTS
       */
      if (
        this.typeOfChartGroupOrAccount === 'barchart-stacked-accounts' ||
        this.typeOfChartGroupOrAccount === 'asset-withdrawal-stacked-accounts' ||
        this.typeOfChartGroupOrAccount === 'asset-deposit-stacked-accounts' ||
        this.typeOfChartGroupOrAccount === 'liability-interest-stacked-accounts' ||
        this.typeOfChartGroupOrAccount === 'liability-amortization-stacked-accounts'
      ) {
        for (const iGroupIdx in this.apiData.groups[this.activeTab]) {
          const oGroup = this.apiData.groups[this.activeTab][iGroupIdx]

          for (const iAcctIdx in oGroup.accounts) {
            const oAccount = oGroup.accounts[iAcctIdx]
            const oTmpDataset = {}
            let iSum = 0

            oTmpDataset.label = oAccount.label
            oTmpDataset.data = []

            let aPeriodValues = []
            switch (this.typeOfChartGroupOrAccount) {
              case 'barchart-stacked-accounts':
                aPeriodValues = oAccount.period_totals
                break
              case 'asset-withdrawal-stacked-accounts':
                aPeriodValues = oAccount.withdrawals
                break
              case 'asset-deposit-stacked-accounts':
                aPeriodValues = oAccount.deposits
                break
              case 'liability-interest-stacked-accounts':
                aPeriodValues = oAccount.interest
                break
              case 'liability-amortization-stacked-accounts':
                aPeriodValues = oAccount.amortization
                break
            }

            for (const sPeriod in aPeriodValues) {
              iSum += aPeriodValues[sPeriod]
              oTmpDataset.data.push((aPeriodValues[sPeriod] * this.multiplier).toFixed(0))
            }
            if (iSum !== 0) {
              oTmpDataset.backgroundColor = oPalette.shift()
              aDatasets.push(oTmpDataset)
            }
          }
        }
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    accountResolution: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
